// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
interface Props {
    to: string,
    text: string,
    state?: any,
}
const ButtonBack = ({ to, text, state = {} }:Props) => {
    const navigate = useNavigate();
    return (
        <button
            onClick={() => navigate(to, { state })}
            className='button fontAtak'
            >{text.toUpperCase()}
        </button>
        // <Link
        //     to={to}
        //     className='button fontAtak'
        //     >{text.toUpperCase()}
        // </Link>
    );
};

export default ButtonBack;
