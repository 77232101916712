import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { exit } from 'process';
import Icons from '../components/icons';

// import Icon from '../icons/penthouse.svg';
interface Props {
    name: string,
};

interface Amenity {
    [key: string]: any;
  }

const amenityList: Amenity = {
    'recamara': {
        'icon': <Icons.Recamara />,
        'label': 'Recámara',
        'i18n': 'icon.bedroom',
        },
    'cochera': {
        'icon': <Icons.Cochera />,
        'label': 'Cochera',
        'i18n': 'icon.garage',
        },
    'tv': {
        'icon': <Icons.TV />,
        'label': 'Smart TV',
        'i18n': 'icon.tv',
        },
    'cocina': {
        'icon': <Icons.Cocina />,
        'label': 'Cocina',
        'i18n': 'icon.kitchen',
        },
    'almacen': {
        'icon': <Icons.Almacen />,
        'label': 'Almacén',
        'i18n': 'icon.storageRoom',
        },
    'lavado': {
        'icon': <Icons.Lavado />,
        'label': 'Área de lavado',
        'i18n': 'icon.laundry',
        },
    'terraza': {
        'icon': <Icons.Terraza />,
        'label': 'Terraza',
        'i18n': 'icon.terrace',
        },
    'sala': {
        'icon': <Icons.Sofa />,
        'label': 'Sala',
        'i18n': 'icon.livingRoom',
        },
    'alberca': {
        'icon': <Icons.Alberca />,
        'label': 'Alberca',
        'i18n': 'icon.swimmingPool',
        },
    'camastro': {
        'icon': <Icons.Camastro />,
        'label': 'Camastros',
        'i18n': 'icon.sunLoungers',
        },
    'comedor_exterior': {
        'icon': <Icons.ComedorOut />,
        'label': 'Comedor Exterior',
        'i18n': 'icon.outdoorDining',
        },
    'grill': {
        'icon': <Icons.Grill />,
        'label': 'Grill',
        'i18n': 'icon.grill',
        },
    'cocktail_pool': {
        'icon': <Icons.Cocktail />,
        'label': 'Cocktail Pool',
        'i18n': 'icon.cocktailPool',
        },
    'tv45': {
        'icon': <Icons.TV />,
        'label': 'Smart TV 45”',
        'i18n': 'icon.tv45',
        },
    'tv75': {
        'icon': <Icons.TV />,
        'label': 'Smart TV 75”',
        'i18n': 'icon.tv75',
        },
    'tv50': {
        'icon': <Icons.TV />,
        'label': 'Smart TV 50”',
        'i18n': 'icon.tv50',
        },
    'acceso_alberca': {
        'icon': <Icons.Alberca />,
        'label': 'Acceso a alberca principal',
        'i18n': 'icon.poolAccess',
        },
    'deck': {
        'icon': <Icons.Deck />,
        'label': 'Deck de madera',
        'i18n': 'icon.deck',
        },
    'vista_mar': {
        'icon': <Icons.Mar />,
        'label': 'Vista al mar',
        'i18n': 'icon.oceanView',
        },
    'sala_exterior': {
        'icon': <Icons.Sofa />,
        'label': 'Sala exterior',
        'i18n': 'icon.outdoorLounge',
        },
    'terraza_techada': {
        'icon': <Icons.Terraza />,
        'label': 'Terraza techada',
        'i18n': 'icon.coveredTerrace',
        },
    'frigobar': {
        'icon': <Icons.Cocina />,
        'label': 'Frigobar',
        'i18n': 'icon.miniFridge',
        },
    'cama_king': {
        'icon': <Icons.CamaKing />,
        'label': 'Cama King Size',
        'i18n': 'icon.kingSizeBed',
        },
    'caja_seguridad': {
        'icon': <Icons.CajaSeguridad />,
        'label': 'Caja de seguridad',
        'i18n': 'icon.safeBox',
        },
    'bano_completo': {
        'icon': <Icons.Bano />,
        'label': 'Baño completo',
        'i18n': 'icon.fullBathroom',
        },
    'vista_jardin': {
        'icon': <Icons.Jardin />,
        'label': 'Vista al jardín',
        'i18n': 'icon.gardenView',
        },
    'cocina_comedor': {
        'icon': <Icons.Comedor />,
        'label': 'Cocineta-Comedor',
        'i18n': 'icon.kitchenDining',
        },
    'lockoff': {
        'icon': <Icons.Lockoff />,
        'label': 'Lockoff',
        'i18n': 'icon.lockoff'
        },
    'medio_bano': {
        'icon': <Icons.Bano />,
        'label': '½ Baño',
        'i18n': 'icon.halfBathroom',
        },
    // 'planta_alta': {
    //     'icon': <Icons.PlantaAlta />,
    //     'label': '½ Baño'
    //     },
    // 'planta_baja': {
    //     'icon': <Icons.PlantaBaja />,
    //     'label': '½ Baño'
    //     }
    
}
// const getIcon = (str:String) => {
//     console.log('getIcon', str);
//     switch(str) {
//         case 'cochera': return <Icons.TV />;
//         default: <Icons.Alberca />
//     }
// };
const AmenityIcon = ({ name }:Props) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    // const exist = Object.keys(amenityList).find((v:any) => 'name');
    // console.log(Object.keys(amenityList).includes(name));
    const exist = Object.keys(amenityList).includes(name);
    if (exist) {
        return (
            <div className='amenityIcon'>
                {amenityList[name].icon || ''}
                <span className="fontCoconat">{t(amenityList[name].i18n)}</span>
            </div>
            // <button onClick={() => navigate(-1)}>go back</button>
        );
    } else {
        return (<div>{name}</div>)
    }
};

export default AmenityIcon;
