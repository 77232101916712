import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import ButtonBack from '../components/buttonBack';
import ButtonHome from '../components/buttonHome';
// import ButtonGoTo from '../components/buttonGoTo';
import Button from '../components/button';
import { Swiper, SwiperSlide , useSwiper} from 'swiper/react';
import { EffectFade } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-fade';
import { useTranslation } from 'react-i18next';
import Icon from '../components/icon';
import AmenityIcon from '../components/amenityIcon';
import LngSwitch from '../components/lngSwitch';
import SwiperIcons from '../components/swiperIcons';

interface Props {
    title?: string,
    name?: string,
    section?: String,
}
const Home = (props:any) => {
    const { t } = useTranslation();
    let _swiper = useSwiper();

    let location = useLocation();
    const fromIndex = location?.state?.index || 0;
    // console.log('detail: from index', fromIndex)

    const [activeTabIndex, setActiveTabIndex] = useState(0);
    // const nextSlide = () => {}
    let timer:any = null;
    useEffect(
        () => {
          return () => {
            clearTimeout(timer)
          }
        },
        []
      )

      const backPath = props.section ?  `/alojamiento/${props.section}` : '/alojamiento';
      const showTabs = props.sections.length > 1;
      // console.log('props.sections', props.sections)
    return (            
            <div className='container containerDetail row'>

                <LngSwitch />
                {/* LEFT */}
                <div className='column beige pH90 splitContent'>

                    {/* NAV */}
                    <nav className='column mb20'>
                        <div className='row justify-space-between'>
                            <div className="">
                                <ButtonBack black to={backPath} state={ { index: fromIndex, homeIndex: 4 } }/>
                                {/* <ButtonGoTo routeName={'/alojamiento'} state={{ index: props.index }} /> */}
                            </div>
                            <div className="">
                                <ButtonHome homeIndex={2} />
                            </div>
                        </div>
                        
                        {/* <div className='alignRight'>
                            <button></button>
                        </div>                */}
                    </nav>

                    <div className="scrollY" 
                      style={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'center',
                        flexDirection: 'column',
                        marginTop: '-50px',
                      }}
                    >
                      <div>
                            <h1 className='fontCoconat font6 textBlue'>{t(props.name)}</h1>
                        </div>                        
                        {/* TEXT */}
                        {/* <p className='fontAtak text-justify mb30'> */}
                        <p className='fontAtak mb30'>
                        {props.desc && t(props.desc)}
                        </p>

                        {/* TABS */}
                        {showTabs ? (
                            <ul className="tabs">
                                {props.sections?.map((v:any, k:number) => (
                                    <li key={k} className={`buttonTab ${activeTabIndex === k ? 'active' : ''}`} onClick={() => setActiveTabIndex(k)}>
                                        <div>
                                            <Icon color={'blue'} name={v.icon} />
                                        </div>
                                        <span className="fontCoconat font12px textBlue">
                                        {t(v.title)}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        ) :  false}

                        <div className="tab-content-container">
                            {props.loremIpsumTitle && <h2 className="fontCoconat">{t(props.loremIpsumTitle)}</h2>}
                            {props.loremIpsumText && <p className="fontAtak">{t(props.loremIpsumText)}</p>}
                            
                            {props.sections?.map((m:any, n:number) => (
                                <div key={n} className={`tab-content ${activeTabIndex === n ? 'active' : ''}`}>
                                    <br/>
                                    {/* <h2 className="fontCoconat">{t(m.title)}</h2> */}
                                    {/* {m.description && <p>{t(m.description)}</p>} */}
                                    {m.size && <label className="textBlue fontCoconat">{t('label.characteristics').toUpperCase()}</label>}
                                    {m.size && <p>{t(m.size)}</p>}
                                    {m.text && <p>{m.text}</p>}
                                    <div className="blankSapce" />
                                    {/* {<label>{t('label.amenities')}</label>} */}
                                    {/* <ul
                                        className='amenities_list'
                                        style={{
                                            // width: 900,
                                            // overflow: 'scroll'
                                        }}
                                        >
                                        {m.amenities?.map((v:any, k:number) => (
                                            <li key={k}><AmenityIcon name={v} /></li>
                                        ))}
                                    </ul> */}
                                </div>
                            ))}
                        </div>
                   

                    </div>
                    {/* end scrollable content */}

                     {/* BUTTONS */}
                     <div>
                        {props.sections?.map((m:any, n:number) => (
                            <div key={n} className={`tab-content ${activeTabIndex === n ? 'active' : ''}`}>
                                <ul
                                    className='amenities_list'
                                    style={{
                                        // width: 900,
                                        // overflow: 'scroll'
                                    }}
                                    >
                                    <SwiperIcons
                                        icons={m.amenities}
                                    />
                                    {/* {m.amenities?.map((v:any, k:number) => (
                                        <li key={k}><AmenityIcon name={v} /></li>
                                    ))} */}
                                </ul>
                            </div>
                        ))}
                     </div>
                     <div className="row justify-space-between mb80 pH20 mt20">
                        {props.map && <Button text={t('button.location')} to={`/${props.map}`} state={{ path: props.path }} />}
                        <Button text={t('button.gallery')} to={`gallery`} />
                    </div>
                    
                </div>



                {/* RIGHT */}
                <div className="splitContent flex-1"
                    style={{
                        // backgroundImage: 'url(/assets/2.jpg)',

                    }}
                >
                    <Swiper
                        effect="fade"
                        spaceBetween={0}
                        slidesPerView={1}
                        // speed={1200}
                        className="swiper-detail-container"
                        modules={[EffectFade]}
                        loop={true}
                        // speed={1200}
                        onSwiper={(swiper:any) => {
                            // console.log(swiper)
                            _swiper = swiper
                            const timer = setInterval(() => {
                                if (_swiper) {
                                    // _swiper.slideNext();
                                }
                            }, 2000)
                        }} 
                        // onSlideChange={(x:any) => console.log('slide change', x.realIndex)}
                        >
                            {props.slideImages?.map((v:any, k:number) => (
                                <SwiperSlide
                                    key={k}
                                    style={{
                                        backgroundImage: `url(/assets/${v})`,
                                    }}
                                />
                            ))}
                        
                        {/* <SwiperSlide
                        style={{
                            backgroundImage: 'url(/assets/consumo_la_palma.jpeg)',
                        }}
                        ></SwiperSlide> */}
                    </Swiper>
                </div>
            </div>
    );
};

export default Home;
