import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ButtonBack from '../components/buttonBack';
import ButtonHome from '../components/buttonHome';
import ButtonGoTo from '../components/buttonGoTo';
import Button from '../components/button';
import { Swiper, SwiperSlide , useSwiper} from 'swiper/react';
import { EffectFade } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-fade';
import { useTranslation } from 'react-i18next';

import LngSwitch from '../components/lngSwitch';
interface Props {
    title?: string,
    name?: string,
}

const Home = (props:any) => {
    // console.log('props', props);
    const { t } = useTranslation();
    let _swiper = useSwiper();
    let location = useLocation();

    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const fromIndex = location?.state?.index || 0;
    // const nextSlide = () => {}
    let timer:any = null;
    useEffect(
        () => {
          return () => {
            clearTimeout(timer)
          }
        },
        []
      )
    let homeIndex = 0;
    console.log('props.section', props);
    switch (props.section) {
        case 'consumo': homeIndex = 0;
            break;
        case 'destinos': homeIndex = 1;
            break;
        case 'amenities': homeIndex = 3;
            break;
        case 'alojamiento': homeIndex = 2;
            break;
    }
    // const backPath = 
    // props.path === 'la-cruz' ||
    // props.path === 'bucerias' ||
    // props.path === 'vallarta' ? '/destinos' : '/consumo';
    const backPath = `/${props.section}`;
    console.log('backpath', backPath)

    return (            
            <div className='container containerDetail row'>

                <LngSwitch />
                {/* LEFT */}
                <div className='column beige pH80 splitContent'>

                    {/* NAV */}
                    <nav className='column mb20'>
                        <div className='row justify-space-between'>
                            <div className="">
                            <ButtonBack black to={backPath} state={ { index: fromIndex, homeIndex } }/>
                                {/* <ButtonGoTo routeName={'/alojamiento'} state={{ index: props.index }} /> */}
                            </div>
                            <div className="">
                                <ButtonHome homeIndex={homeIndex}/>
                            </div>
                        </div>
                        {/* <div>
                            <h1 className='fontCoconat font6'>{t(props.name)}</h1>
                        </div>                         */}
                        {/* <div className='alignRight'>
                            <button></button>
                        </div>                */}
                    </nav>

                    {/* TEXT */}
                    {/* <p className='fontAtak text-justify mb60'> */}
                    <div style={{
                      display: 'flex',
                      flex: 1,
                      justifyContent: 'center',
                      flexDirection: 'column',
                      marginTop: '-50px',
                    }}>
                        <div>
                            <h1 className='fontCoconat font6'>{t(props.name)}</h1>
                        </div>     
                    <p className='fontAtak mb60'>
                    {props.desc && t(props.desc)}
                    </p>

                    <ul className="tabs">
                        {props.sections?.map((v:any, k:number) => (
                            <li key={k} className={`buttonTab ${activeTabIndex === k ? 'active' : ''}`} onClick={() => setActiveTabIndex(k)}>{t(v.title)}</li>
                        ))}
                    </ul>

                    <div className="tab-content-container">
                        {props.name && <h2 className="fontCoconat">{t(props.loremIpsumTitle)}</h2>}
                        {props.loremIpsumText && <p className="fontAtak">{t(props.loremIpsumText)}</p>}
                        
                        {props.sections?.map((m:any, n:number) => (
                             <div key={n} className={`tab-content ${activeTabIndex === n ? 'active' : ''}`}>
                                <br/>
                                <h2 className="fontCoconat">{t(m.title)}</h2>
                                {m.size && <label>{t('label.size')}</label>}
                                {m.size && <p>{t(m.size)}</p>}
                                {m.description && <label>{t('label.amenities')}</label>}
                                {m.description && <p>{t(m.description)}</p>}
                                {m.text && <p>{m.text}</p>}
                             </div>
                        ))}
                    </div>
                    {/* BUTTONS */}
                    <div className='row justify-space-between'>
                        {props.map && <Button text={t('button.location')} to={`/${props.map}`} state={{ path: props.path }} />}
                        <Button text={t('button.gallery')} to={`gallery`} />
                    </div>

                    </div>
                    
                </div>

                {/* RIGHT */}
                <div className="splitContent flex-1"
                    style={{
                        // backgroundImage: 'url(/assets/2.jpg)',

                    }}
                >
                    <Swiper
                        effect="fade"
                        spaceBetween={0}
                        slidesPerView={1}
                        // speed={1200}
                        className="swiper-detail-container"
                        modules={[EffectFade]}
                        loop={true}
                        // speed={1200}
                        onSwiper={(swiper:any) => {
                            // console.log(swiper)
                            _swiper = swiper
                            const timer = setInterval(() => {
                                if (_swiper) {
                                    // _swiper.slideNext();
                                }
                            }, 2000)
                        }} 
                        // onSlideChange={(x:any) => console.log('slide change', x.realIndex)}
                        >
                            {props.slideImages?.map((v:any, k:number) => (
                                <SwiperSlide
                                    key={k}
                                    style={{
                                        backgroundImage: `url(/assets/${v})`,
                                    }}
                                />
                            ))}
                        
                        {/* <SwiperSlide
                        style={{
                            backgroundImage: 'url(/assets/consumo_la_palma.jpeg)',
                        }}
                        ></SwiperSlide> */}
                    </Swiper>
                </div>
            </div>
    );
};

export default Home;
