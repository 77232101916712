// import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import Icons from '../components/icons';

interface Props {
    name: string,
    color?: String,
};

interface Icon {
    [key: string]: any;
  }

const iconList: Icon = {
    'recamara': {
        'icon': <Icons.Recamara />,
        'label': 'Recámara'
        },
    'cochera': {
        'icon': <Icons.Cochera />,
        'label': 'Cochera'
        },
    'tv': {
        'icon': <Icons.TV />,
        'label': 'Smart TV'
        },
    'cocina': {
        'icon': <Icons.Cocina />,
        'label': 'Cocina'
        },
    'almacen': {
        'icon': <Icons.Almacen />,
        'label': 'Almacén'
        },
    'lavado': {
        'icon': <Icons.Lavado />,
        'label': 'Área de lavado'
        },
    'terraza': {
        'icon': <Icons.Terraza />,
        'label': 'Terraza'
        },
    'sala': {
        'icon': <Icons.Sofa />,
        'label': 'Sala'
        },
    'alberca': {
        'icon': <Icons.Alberca />,
        'label': 'Alberca'
        },
    'camastro': {
        'icon': <Icons.Camastro />,
        'label': 'Camastros'
        },
    'comedor_exterior': {
        'icon': <Icons.ComedorOut />,
        'label': 'Comedor Exterior'
        },
    'grill': {
        'icon': <Icons.Grill />,
        'label': 'Grill'
        },
    'cocktail_pool': {
        'icon': <Icons.Cocktail />,
        'label': 'Cocktail Pool'
        },
    'tv45': {
        'icon': <Icons.TV />,
        'label': 'Smart TV 45”'
        },
    'tv75': {
        'icon': <Icons.TV />,
        'label': 'Smart TV 75”'
        },
    'tv50': {
        'icon': <Icons.TV />,
        'label': 'Smart TV 50”'
        },
    'acceso_alberca': {
        'icon': <Icons.Alberca />,
        'label': 'Acceso a alberca principal'
        },
    'deck': {
        'icon': <Icons.Deck />,
        'label': 'Deck de madera'
        },
    'vista_mar': {
        'icon': <Icons.Mar />,
        'label': 'Vista al mar'
        },
    'sala_exterior': {
        'icon': <Icons.Sofa />,
        'label': 'Sala exterior'
        },
    'terraza_techada': {
        'icon': <Icons.Terraza />,
        'label': 'Terraza techada'
        },
    'frigobar': {
        'icon': <Icons.Cocina />,
        'label': 'Frigobar'
        },
    'cama_king': {
        'icon': <Icons.CamaKing />,
        'label': 'Cama King Size'
        },
    'caja_seguridad': {
        'icon': <Icons.CajaSeguridad />,
        'label': 'Caja de seguridad'
        },
    'bano_completo': {
        'icon': <Icons.Bano />,
        'label': 'Baño completo'
        },
    'vista_jardin': {
        'icon': <Icons.Jardin />,
        'label': 'Vista al jardín'
        },
    'cocina_comedor': {
        'icon': <Icons.Comedor />,
        'label': 'Cocineta-Comedor'
        },
    'lockoff': {
        'icon': <Icons.Lockoff />,
        'label': 'Lockoff'
        },
    'medio_bano': {
        'icon': <Icons.Bano />,
        'label': '½ Baño'
        },
    'planta_alta': {
        'icon': <Icons.PlantaAlta />,
        'label': '½ Baño'
        },
    'planta_baja': {
        'icon': <Icons.PlantaBaja />,
        'label': '½ Baño'
        },
    'piso1': {
        'icon': <Icons.Piso1 />,
        'label': '½ Baño'
        },
    'piso2': {
        'icon': <Icons.Piso2 />,
        'label': '½ Baño'
        },
    'piso3': {
        'icon': <Icons.Piso3 />,
        'label': '½ Baño'
        },
    'home': {
        'icon': <Icons.Home />,
        'label': 'Home',
    }
    
}
// const getIcon = (str:String) => {
//     console.log('getIcon', str);
//     switch(str) {
//         case 'cochera': return <Icons.TV />;
//         default: <Icons.Alberca />
//     }
// };
const Icon = ({ name, color = 'black' }:Props) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    // const exist = Object.keys(iconList).find((v:any) => 'name');
    // console.log('NAME', name);
    const exist = Object.keys(iconList).includes(name);
    if (exist) {
        return (
            <div className={`icon ${color}`}>
                {iconList[name].icon || ''}
            </div>
        );
    } else {
        return (<div>{name}</div>)
    }
};

export default Icon;
