import React, { useState, useEffect } from 'react';
import useWindowDimensions from '../hooks/useWindowDimensions';
import ButtonBack from '../components/buttonBack';
import PopUpMap from '../components/popUpMap';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
interface Spot {
    name: string,
    category: string,
    link: string,
    text?: string,
    x: number,
    y: number,
};
const Home = () => {
    let location = useLocation();
    const { t } = useTranslation();
    const map = {
        height: 1080,
        width: 2353,
        imagePath: '/assets/map5.jpg',
    }
    const emptySpot = {
        name: '',
        category: '',
        link: '',
        x: 0,
        y: 0,
    };
    const { height, width } = useWindowDimensions();
    // const navigate = useNavigate();

    const [isVisiblePopUp, setIsVisiblePopUp] = useState(false);
    const [currentSpot, setCurrentSpot] = useState<Spot>(emptySpot);
    const [isVisibleSection, setIsVisibleSection] = useState(false);

    const mapOriginalHeight = map.height; // original
    const mapOriginalWidth = map.width; // original
    const mapHeight = height;
    const mapWidth = (mapOriginalWidth * height) / mapOriginalHeight;

    const sectionWidth = (2725 * height) / 1434;
    const showPopUp = (spot:any) => {
        setIsVisiblePopUp(true);
        setCurrentSpot(spot);
    };
    const hidePopUp = () => {
        setCurrentSpot(emptySpot)
        setIsVisiblePopUp(false);
    };
    const spotsObj: {[key: string]: Spot} = {
        'la-cruz': {
            name: t('destinos.laCruz.name'),
            category: t('map.category.destinations'),
            link: '/destinos/la-cruz',
            text: t('destinos.laCruz.desc'),
            x: (mapHeight * 1020) / mapOriginalHeight,
            y: (mapHeight * 340) / mapOriginalHeight,
        },
        bucerias: {
            name: t('destinos.bucerias.name'),
            category: t('map.category.destinations'),
            link: '/destinos/bucerias',
            text: t('destinos.bucerias.desc'),
            x: (mapHeight * 1295) / mapOriginalHeight,
            y: (mapHeight * 360) / mapOriginalHeight,
        },
        vallarta: {
            name: t('destinos.vallarta.name'),
            category: t('map.category.destinations'),
            link: '/destinos/vallarta',
            text: t('destinos.vallarta.desc'),
            x: (mapHeight * 1540) / mapOriginalHeight,
            y: (mapHeight * 900) / mapOriginalHeight,
        },
    };
    const spots = Object.keys(spotsObj).map((v:any, k:number) => {
        return (
        <circle key={k} onClick={() => showPopUp(spotsObj[v])} className={`mapSpot ${currentSpot.name === spotsObj[v].name ? 'active' : ''}`} r="14" cx={spotsObj[v].x} cy={spotsObj[v].y} >
        </circle>)
    });

    useEffect(() => {
        if (spotsObj[location.state?.path]) {
            setCurrentSpot(spotsObj[location.state?.path]);
            setIsVisiblePopUp(true);
        }
    }, [location.state])
    const showSection = () => setIsVisibleSection(true);
    return (
        <div className=''>
            <nav
                style={{
                    position: 'fixed',
                    top: 0,
                    width: '100%',
                    textAlign: 'center',
                    zIndex: 99,
                    marginLeft: 50,
                }}
            >
                <ButtonBack dark black />
            </nav>

            {isVisiblePopUp && (
                <PopUpMap hidePopUp={hidePopUp} {...currentSpot} />
            )}

            <div className="mapContainer" style={{ width: isVisibleSection ? sectionWidth : mapWidth }}>
                {!isVisibleSection && <svg id="svg" width={mapWidth} height={mapHeight}>
                    <image id="IMG" className="map" xlinkHref={map.imagePath} width={mapWidth} height={mapHeight}></image>
                    {spots}
                </svg>}
            </div>
        </div>
    );
};

export default Home;
