import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import "./components.css";
import { useTranslation, Trans } from 'react-i18next';

interface lng {
    nativeName: string,
};
const lngs = {
    en: { nativeName: 'English' },
    es: { nativeName: 'Español' },
};
interface Props {
    to: string,
    text: string,
}
const LngSwitch = ({ dark= false }:{ dark?: Boolean }) => {
    // const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    return (
        <div className={`lngSwitch ${dark && 'dark'}`}>
            {Object.keys(lngs).map((lng) => (
                <button className={`${i18n.resolvedLanguage !== lng ? 'disabled' : ''}`} key={lng} onClick={() => i18n.changeLanguage(lng)} disabled={i18n.resolvedLanguage === lng}>{lng}</button>
            ))}
        </div>
    );
};

export default LngSwitch;
