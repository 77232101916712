import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Link,
  useLocation,
  Route,
  Routes
} from "react-router-dom";
import "./transition.css";


// import Home from './views/Home';
// import Cosumo from './views/Consumo';
// import Esparcimiento from './views/Esparcimiento';
// import Alojamiento from './views/Alojamiento';
// import Cruz from './views/Cruz';
import Gallery from './views/Gallery';
import Map from './views/Map';
import MapDetail from './views/MapDetail';
import Test from './views/Test';
import Zoom from './views/SwiperZoom';
import Detail from './views/Detail';
import DetailAlojamientos from './views/DetailAlojamientos';
import Home from './views/Home';
import Section from './views/Section';
import Map1 from './views/Map1';
import Map2 from './views/Map2';
import Map3 from './views/Map3';
import Map4 from './views/Map4';
import Map5 from './views/Map5';

import data from './data';
import dataAlojamiento from './data/alojamiento';
import { useTranslation } from "react-i18next";
import alojamiento from "./data/alojamiento";

export default function App() {
  return (
    <BrowserRouter>
      <div className={`App`}>       
        <Content />
      </div>
    </BrowserRouter>
  );
}

function Content() {
  const { t } = useTranslation();
  const location = useLocation();

  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState("fadeIn");

  useEffect(() => {
    if (location !== displayLocation) setTransistionStage("fadeOut");
  }, [location, displayLocation]);

  // const useDisablePinchZoomEffect = () => {
    useEffect(() => {
      const disablePinchZoom = (e:any) => {
        if (e.touches.length > 1) {
          e.preventDefault()
        }
      }
      document.addEventListener("touchmove", disablePinchZoom, { passive: false })
      return () => {
        document.removeEventListener("touchmove", disablePinchZoom)
      }
    }, [])
  // }

  return (
    <div
      className={`${transitionStage}`}
      onAnimationEnd={() => {
        if (transitionStage === "fadeOut") {
          setTransistionStage("fadeIn");
          setDisplayLocation(location);
        }
      }}
    >
      <Routes location={displayLocation}>
        {/* <Route path="/old" element={<Home />} /> */}

        <Route path="/" element={<Home />} />

        {/* #CONSUMO */}
        <Route path="/consumo" element={<Section slides={data.consumo} title={t('consumo')} section={'consumo'} />} />
        {data.consumo.map((v, k) => (
          <Route path={`/consumo/${v.path}`} element={<Detail {...v} key={k} />} />
        ))}

        {/* DESTINOS */}
        <Route path="/destinos" element={<Section slides={data.destinos} title={t('destinos')} section={'destinos'} />} />
        {data.destinos.map((v, k) => (
          <Route path={`/destinos/${v.path}`} element={<Detail {...v} key={k} />} />
        ))}

        {/* AREAS */}
        <Route path="/amenidades" element={<Section slides={data.amenities} title={t('label.amenities')} section={'amenities'} />} />
        {data.amenities.map((v, k) => (
          <Route path={`/amenidades/${v.path}`} element={<Detail {...v} key={k} />} />
        ))}

        {/* ALOJAMIENTO */}
        <Route path="/alojamiento" element={<Section slides={data.alojamiento} title={t('alojamiento')} section={'alojamiento'} />} />
        {data.alojamiento.map((v, k) => 
        v.path === 'marine-collection' || v.path === 'malabar' ? false : (
          <Route path={`/alojamiento/${v.path}`} element={<DetailAlojamientos {...v} />} key={k} />
        ))}

        {/* ESPECIAL CASE: MARINE / MALABAR */}
        <Route path={`/alojamiento/marine-collection`} element={<Section slides={dataAlojamiento.marine} title={'Marine Collection'} section={'marine-collection'} />} />
        {dataAlojamiento.marine.map((v, k) => 
          <Route path={`/alojamiento/marine-collection/${v.path}`} element={<DetailAlojamientos {...v} />} key={k} />
        )}
        <Route path={`/alojamiento/malabar`} element={<Section slides={dataAlojamiento.malabar} title={'Casa Malabar'} section={'malabar'} />} />
        {dataAlojamiento.malabar.map((v, k) => 
          <Route path={`/alojamiento/malabar/${v.path}`} element={<DetailAlojamientos {...v} />} key={k} />
        )}

        {/* GALERIES */}
        {data.alojamiento.map((v, k) => (
          <Route path={`/alojamiento/${v.path}/gallery`} element={<Gallery {...v} />} key={k} />
        ))}
        {dataAlojamiento.malabar.map((v, k) =>
          <Route path={`/alojamiento/malabar/${v.path}/gallery`} element={<Gallery {...v} />} key={k} />
        )}
        {dataAlojamiento.marine.map((v, k) =>
          <Route path={`/alojamiento/marine-collection/${v.path}/gallery`} element={<Gallery {...v} />} key={k} />
        )}

        {data.destinos.map((v, k) => (
          <Route path={`/destinos/${v.path}/gallery`} element={<Gallery {...v} />} key={k} />
        ))}

        {data.consumo.map((v, k) => (
          <Route path={`/consumo/${v.path}/gallery`} element={<Gallery {...v} />} key={k} />
        ))}

        {data.amenities.map((v, k) => (
          <Route path={`/amenidades/${v.path}/gallery`} element={<Gallery {...v} />} key={k} />
        ))}
        

        {/* <Route path="/esparcimiento" element={<Esparcimiento />} />
        <Route path="/alojamiento" element={<Alojamiento />} />
        <Route path="/la-cruz" element={<Cruz />} /> */}
        <Route path="/galeria" element={<Gallery />} />
        <Route path="/mapa" element={<Map />} />
        <Route path="/test" element={<Test />} />
        {/* <Route path="/home" element={<Zoom />} /> */}
        <Route path="/detail" element={<Detail />} />
        <Route path="/map1" element={<Map1 />} />
        <Route path="/map2" element={<Map2 />} />
        <Route path="/map3" element={<Map3 />} />
        <Route path="/map4" element={<Map4 />} />
        <Route path="/map5" element={<Map5 />} />

      </Routes>
    </div>
  );
}
