import React, { useState, useEffect } from 'react';
import useWindowDimensions from '../hooks/useWindowDimensions';
// 
import ButtonBack from '../components/buttonBack';
import PopUpMap from '../components/popUpMap';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
interface Spot {
    name: string,
    category: string,
    link: string,
    text?: string,
    x: number,
    y: number,
};
const Home = () => {
    let location = useLocation();
    const { t } = useTranslation();
    const map = {
        height: 2235,
        width: 3812,
        imagePath: '/assets/map4.jpg',
    }
    const emptySpot = {
        name: '',
        category: '',
        link: '',
        x: 0,
        y: 0,
    };
    const { height, width } = useWindowDimensions();
    // const navigate = useNavigate();

    const [isVisiblePopUp, setIsVisiblePopUp] = useState(false);
    const [currentSpot, setCurrentSpot] = useState<Spot>(emptySpot);
    const [isVisibleSection, setIsVisibleSection] = useState(false);

    const mapOriginalHeight = map.height; // original
    const mapOriginalWidth = map.width; // original
    const mapHeight = height;
    const mapWidth = (mapOriginalWidth * height) / mapOriginalHeight;

    const sectionWidth = (2725 * height) / 1434;
    const showPopUp = (spot:any) => {
        setIsVisiblePopUp(true);
        setCurrentSpot(spot);
    };
    const hidePopUp = () => {
        setCurrentSpot(emptySpot)
        setIsVisiblePopUp(false);
    };
    const spotsObj: {[key: string]: Spot} = {
        // beach: {
        //     name: 'beach',
        //     category: t('map.category.amenities'),
        //     link: '/amenidades/games',
        //     text: t('amenities.games.desc'),
        //     x: (mapHeight * 3360) / mapOriginalHeight,
        //     y: (mapHeight * 870) / mapOriginalHeight,
        // },
        gym: {
            name: t('amenities.gym.name'),
            category: t('map.category.amenities'),
            link: '/consumo/palapa',
            text: t('amenities.gym.desc'),
            x: (mapHeight * 200) / mapOriginalHeight,
            y: (mapHeight * 1260) / mapOriginalHeight,
        },
        faro: {
            name: t('consumo.faro.name'),
            category: t('map.category.gastronomy'),
            link: '/consumo/faro',
            text: t('consumo.faro.desc'),
            x: (mapHeight * 2950) / mapOriginalHeight,
            y: (mapHeight * 640) / mapOriginalHeight,
        },
    };
    const spots = Object.keys(spotsObj).map((v:any, k:number) => {
        return (
        <circle key={k} onClick={() => showPopUp(spotsObj[v])} className={`mapSpot ${currentSpot.name === spotsObj[v].name ? 'active' : ''}`} r="14" cx={spotsObj[v].x} cy={spotsObj[v].y} >
        </circle>)
    });

    useEffect(() => {
        if (spotsObj[location.state?.path]) {
            setCurrentSpot(spotsObj[location.state?.path]);
            setIsVisiblePopUp(true);
        }
    }, [location.state])
    const showSection = () => setIsVisibleSection(true);
    return (
        <div className=''>
            <nav
                style={{
                    position: 'fixed',
                    top: 0,
                    width: '100%',
                    textAlign: 'center',
                    zIndex: 99,
                    marginLeft: 50,
                }}
            >
                <ButtonBack dark black />
            </nav>

            {isVisiblePopUp && (
                <PopUpMap hidePopUp={hidePopUp} {...currentSpot} />
            )}

            <div className="mapContainer" style={{ width: isVisibleSection ? sectionWidth : mapWidth }}>
                {!isVisibleSection && <svg id="svg" width={mapWidth} height={mapHeight}>
                    <image id="IMG" className="map" xlinkHref={map.imagePath} width={mapWidth} height={mapHeight}></image>
                    {spots}
                </svg>}
            </div>
        </div>
    );
};

export default Home;
