import React, { useState, useEffect, useRef, useCallback } from 'react';
import ButtonBack from '../components/buttonBack';
import { Pagination, Zoom, Parallax, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/zoom';
import useWindowDimensions from '../hooks/useWindowDimensions';

import AmenityIcon from '../components/amenityIcon';
import Icon from '../components/icon';

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Link } from 'react-router-dom';

const View = () => {
    let _swiper = useSwiper();
    const { height } = useWindowDimensions();
    const [isSlideReady, setIsSlideReady] = useState(false);
    const [isInTransition, setIsInTransition] = useState(false);

    const slides = [
        {
            subtitle: 'Centros de',
            title: 'CONSUMO',
            image: '/assets/home_consumo.jpg',
            link: '/consumo/',
        },
        {
            subtitle: 'Conóce',
            title: 'Destinos',
            image: '/assets/home_destinos.jpg',
            link: '/la-cruz/',
        },
        {
            subtitle: 'Áareas de',
            title: 'ESPARCIMIENTO',
            image: '/assets/esparcimiento.jpg',
            link: '/esparcimiento/',
        },
        {
            subtitle: 'Nuestros',
            title: 'ALOJAMIENTOS',
            image: '/assets/alojamiento.jpg',
            link: '/alojamiento/',
        },
    ]
    const images = [
        'a.jpg',
        'b.jpg',
        'c.jpg',
    ];

    const nextSlide = () => {
        // console.log('nextslide', _swiper)
        // setIsInTransition(true);
        if (!_swiper) return;
        // _swiper.zoom.in(.7)
        
        setTimeout(() => {
            _swiper.slideNext(1200)
            // _swiper.disable()
        }, 600)
    }
    const prevSlide = () => {
        if (!_swiper) return;
        // _swiper.zoom.out()
        setTimeout(() => {
            _swiper.slidePrev(1200)
        }, 600)
    }
    // console.log(_swiper?.activeIndex)
    const test = useCallback(() => {
        console.log('TEST FUNCTIION')
        // setIsSlideReady(true)
        console.log('after')
    }, [isSlideReady]);
    // const test = () => {
        // setIsSlideReady(true)
    // }
    const amenities = [
        'recamara',
        'cochera',
        'tv',
        'cocina',
        'almacen',
        'lavado',
        'terraza',
        'sala',
        'alberca',
        'camastro',
        'comedor_exterior',
        'grill',
        'cocktail_pool',
        'tv45',
        'tv75',
        'acceso_alberca',
        'deck',
        'vista_mar',
        'sala_exterior',
        'terraza_techada',
        'frigobar',
        'cama_king',
        'caja_seguridad',
        'bano_completo',
        'vista_jardin',
        'cocina_comedor',
        'lockoff',
        'medio_bano',
    ]
    const icons = [
        'planta_alta',
        'planta_baja',
        'piso1',
        'piso2',
        'piso3',
    ]
    return (
        <>
            <div className=" fashion-slider"
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                {amenities?.map((v:any, k:number) => (
                    <li key={k}><AmenityIcon name={v} /></li>
                ))}
            </div>

            <div className=" fashion-slider"
            style={{
                display: 'flex',
                flexDirection: 'row',
            }}>
                {icons?.map((v:any, k:number) => (
                    <li key={k}><Icon name={v} /></li>
                ))}
            </div>
        </>
    );
};

export default View;
