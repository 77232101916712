const CajaSeguridad = () => (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
  <path className="cls-1" d="M71.37,15.84H28.63c-2.75,0-4.99,2.17-4.99,4.85v44.69c0,2.61,2.14,4.73,4.81,4.83v1.64c0,1.1.9,2,2,2h6.98c1.1,0,2-.9,2-2v-1.62h19.33v1.62c0,1.1.9,2,2,2h6.98c1.1,0,2-.9,2-2v-1.62h1.63c2.75,0,4.99-2.17,4.99-4.85V20.69c0-2.67-2.24-4.85-4.99-4.85ZM37.43,71.84h-6.98v-1.62h6.98v1.62ZM67.74,71.84h-6.98v-1.62h6.98v1.62ZM74.36,65.38c0,1.57-1.34,2.85-2.99,2.85H28.63c-1.65,0-2.99-1.28-2.99-2.85V20.69c0-1.57,1.34-2.85,2.99-2.85h42.74c1.65,0,2.99,1.28,2.99,2.85v44.69Z"/>
  <path className="cls-1" d="M70.36,24.21v-.9c0-1.65-1.35-3-3-3H30.82c-1.65,0-3,1.35-3,3v39.43c0,1.65,1.35,3,3,3h36.54c1.65,0,3-1.35,3-3v-.89c.9-.2,1.58-1,1.58-1.95v-8.5c0-.96-.68-1.76-1.58-1.95v-12.83c.9-.2,1.58-1,1.58-1.95v-8.5c0-.96-.68-1.76-1.58-1.95ZM68.36,24.17c-1.09.02-1.96.91-1.96,2v8.5c0,1.09.88,1.98,1.96,2v12.74c-1.09.02-1.96.91-1.96,2v8.5c0,1.09.88,1.98,1.96,2v.85c0,.55-.45,1-1,1H30.82c-.55,0-1-.45-1-1V23.31c0-.55.45-1,1-1h36.54c.55,0,1,.45,1,1v.85ZM69.94,59.9h-.57s0,0,0,0,0,0,0,0h-.96v-8.5h1.54v8.5ZM69.94,34.66h-.57s0,0,0,0,0,0,0,0h-.96v-8.5h1.54v8.5Z"/>
  <path className="cls-1" d="M49.09,39.61c-1.89,0-3.42,1.54-3.42,3.42s1.54,3.42,3.42,3.42,3.42-1.54,3.42-3.42-1.54-3.42-3.42-3.42ZM49.09,44.45c-.79,0-1.42-.64-1.42-1.42s.64-1.42,1.42-1.42,1.42.64,1.42,1.42-.64,1.42-1.42,1.42Z"/>
  <path className="cls-1" d="M49.09,35.62c-4.09,0-7.41,3.33-7.41,7.41s3.33,7.41,7.41,7.41,7.41-3.33,7.41-7.41-3.33-7.41-7.41-7.41ZM49.09,48.44c-2.99,0-5.41-2.43-5.41-5.41s2.43-5.41,5.41-5.41,5.41,2.43,5.41,5.41-2.43,5.41-5.41,5.41Z"/>
  <path className="cls-1" d="M49.09,33.37c.55,0,1-.45,1-1v-6.14c0-.55-.45-1-1-1s-1,.45-1,1v6.14c0,.55.45,1,1,1Z"/>
  <path className="cls-1" d="M49.09,52.99c-.55,0-1,.45-1,1v6.14c0,.55.45,1,1,1s1-.45,1-1v-6.14c0-.55-.45-1-1-1Z"/>
  <path className="cls-1" d="M40.39,36.68l-5.24-3.21c-.47-.29-1.09-.14-1.38.33-.29.47-.14,1.09.33,1.38l5.24,3.21c.16.1.34.15.52.15.34,0,.67-.17.85-.48.29-.47.14-1.09-.33-1.38Z"/>
  <path className="cls-1" d="M64.07,51.18l-5.24-3.21c-.47-.29-1.09-.14-1.38.33-.29.47-.14,1.09.33,1.38l5.24,3.21c.16.1.34.15.52.15.34,0,.67-.17.85-.48.29-.47.14-1.09-.33-1.38Z"/>
  <path className="cls-1" d="M63.51,34.2l-5.39,2.93c-.49.26-.67.87-.4,1.36.18.33.53.52.88.52.16,0,.33-.04.48-.12l5.39-2.93c.49-.26.67-.87.4-1.36s-.87-.67-1.36-.4Z"/>
  <path className="cls-1" d="M39.11,47.47l-5.39,2.93c-.49.26-.67.87-.4,1.36.18.33.53.52.88.52.16,0,.33-.04.48-.12l5.39-2.93c.49-.26.67-.87.4-1.36s-.87-.66-1.36-.4Z"/>
</svg>
)
export default CajaSeguridad;