const TV = () => (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
  <defs>
    <style>
    </style>
  </defs>
  <path className="cls-1" d="M74.7,27.63H25.3c-2.37,0-4.3,1.93-4.3,4.31v30.11c0,2.37,1.93,4.3,4.3,4.3h17.71v4.02h-6c-.55,0-1,.45-1,1s.45,1,1,1h25.97c.55,0,1-.45,1-1s-.45-1-1-1h-6v-4.02h17.71c2.37,0,4.3-1.93,4.3-4.3v-30.11c0-2.37-1.93-4.31-4.3-4.31ZM54.98,70.37h-9.97v-4.02h9.97v4.02ZM77,62.05c0,1.27-1.03,2.3-2.3,2.3H25.3c-1.27,0-2.3-1.03-2.3-2.3v-30.11c0-1.27,1.03-2.31,2.3-2.31h49.39c1.27,0,2.3,1.03,2.3,2.31v30.11Z"/>
  <path className="cls-1" d="M72,32.63H28c-1.1,0-2,.9-2,2v24.72c0,1.1.9,2,2,2h44c1.1,0,2-.9,2-2v-24.72c0-1.1-.9-2-2-2ZM72,59.35H28s0-24.72,0-24.72h44v24.72Z"/>
</svg>
)
export default TV;