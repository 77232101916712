import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { useNavigate } from 'react-router-dom';
// import ImageMapper from 'react-img-mapper';

const Home = () => {
    // const URL = 'https://raw.githubusercontent.com/img-mapper/react-docs/master/src/assets/example.jpg';
    // const MAP = {
    //     name: 'my-map',
    //     // GET JSON FROM BELOW URL AS AN EXAMPLE AND PUT IT HERE
    //     areas: 'https://raw.githubusercontent.com/img-mapper/react-docs/master/src/assets/example.json',
    // };
    const { height, width } = useWindowDimensions();
    const navigate = useNavigate();

    const [isVisiblePopUp, setIsVisiblePopUp] = useState(false);
    const [currentSpot, setCurrentSpot] = useState('');
    const [isVisibleSection, setIsVisibleSection] = useState(false);

    const mapOriginalHeight = 2753; // original
    // const mapOriginalWidth = 17727; // original
    const mapHeight = height;
    const mapWidth = (17727 * height) / mapOriginalHeight;

    const testLobbyX = (mapHeight * 1770) / mapOriginalHeight;
    const testLobbyY = (mapHeight * 1700) / mapOriginalHeight;
    const spotSpaX = (mapHeight * 2600) / mapOriginalHeight;
    const spotSpaY = (mapHeight * 1470) / mapOriginalHeight;
    const testSectionX = (mapHeight * 500) / mapOriginalHeight;
    const testSectionY = (mapHeight * 970) / mapOriginalHeight;

    const sectionHeight = height;
    const sectionWidth = (2725 * height) / 1434;
    const showPopUp = (spotName:string) => {
        setIsVisiblePopUp(true);
        setCurrentSpot(spotName);
    };
    const hidePopUp = () => {
        setIsVisiblePopUp(false);
    };
    const showSection = () => setIsVisibleSection(true);
    return (
        <div className=''>
            {/* <div> */}
            <button className='buttonMenu' onClick={() => navigate("/")}>MENÚ</button>
            {/* </div> */}
            {/* <img src="/map.jpg" alt="map" /> */}
            {/* <img useMap="#primary" src="http://placehold.it/350x150" alt="350 x 150 pic"></img>
            <map name="primary">
                <area shape="circle" coords="75,75,75" href="left.html" />
                <area shape="circle" coords="275,75,75" href="right.html" />
            </map> */}

            {/* <img src="/map.jpg" alt="map" /> */}
            {isVisiblePopUp && (
                <div className='quickView' style={{
                    top: (mapHeight / 2) - 100,
                    left: (width / 2) - 100,
                }}>
                    <button onClick={hidePopUp}>x</button>
                    <h4>{currentSpot}</h4>
                </div>
            )}

            <div className="mapContainer" style={{ width: isVisibleSection ? sectionWidth : mapWidth }}>
                {!isVisibleSection && <svg id="svg" width={mapWidth} height={mapHeight}>
                    {/* <image id="IMG" xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="https://s3-us-west-2.amazonaws.com/s.cdpn.io/222579/SOFTest.jpg" width="504" height="582"></image>             */}
                    <image id="IMG" xlinkHref="/map.jpg" width={mapWidth} height={mapHeight}></image>
                    {/* <polygon className="polygon" points="86,244 165,214 166,222 201,209 213,228 201,235 222,270 152,303 80,276" fill="red" /> */}
                    {/* <rect onClick={() => showSection()} className="spotTest" width={(mapHeight * 1200) / mapOriginalHeight} height={(mapHeight * 700) / mapOriginalHeight} x={testSectionX} y={testSectionY} fill="rgba(256, 256, 256, .6)"> */}
                        <svg id="svgTest" className="spotTest" onClick={() => showSection()} width={(mapHeight * 2725) / mapOriginalHeight} height={(mapHeight * 1434) / mapOriginalHeight} x={testSectionX} y={testSectionY} scale={1}>
                            <image id="lobby" xlinkHref="/map/lobby.jpg" width={(mapHeight * 2725) / mapOriginalHeight} height={(mapHeight * 1434) / mapOriginalHeight}></image>
                        </svg>
                    {/* </rect> */}
                    <circle onClick={() => showPopUp('lobby')} className="polygon" r="14" cx={testLobbyX} cy={testLobbyY} fill="red" />
                    <circle onClick={() => showPopUp('lobby')} className="polygon" r="14" cx={testLobbyX} cy={testLobbyY} fill="red" />
                    <circle onClick={() => showPopUp('spa')} className="polygon" r="14" cx={spotSpaX} cy={spotSpaY} fill="red" />
                </svg>}

                {isVisibleSection && <svg className="lobby" width={sectionWidth} height={sectionHeight} onClick={() => setIsVisibleSection(false)}>
                    <image id="IMG2" xlinkHref="/map/lobby.jpg" width={sectionWidth} height={sectionHeight}></image>
                </svg>}
            </div>
            

            
            {/* <ImageMapper src={URL} map={MAP} />; */}
        </div>
    );
};

export default Home;
