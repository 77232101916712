import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

interface Props {
    dark?: Boolean,
    homeIndex?: number,
    // routeName: String,
    // state: any,
};
const HomeButton = ({ dark, homeIndex = 0 }:Props) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const navigateTo = () => {
        // const homeIndex = state.homeIndex || 0;
        window.location.href=`/home?lng=${i18n.resolvedLanguage}&index=${homeIndex}`;
    };
    return (
        <div className={`homeButton ${dark && 'dark'} mb60`} onClick={() => navigateTo()}>
            {/* <div> */}
            <svg width="26" height="26" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="22" height="22" fill="black"/>
                <path d="M3.52002 12.32H5.25955V17.6H9.26048V13.76H12.7396V17.6H16.7405V12.32H18.48L10.8261 5.28003L3.52002 12.32Z" fill="#FFF6E5"/>
            </svg>
            <span className="fontAtak">{t('home').toUpperCase()}</span>
            {/* </div> */}
        </div>
        // <button onClick={() => navigate(-1)}>go back</button>
    );
};

export default HomeButton;
