// import i18next from "i18next";
// const { t } = i18next;

const marine = [
    {
        section: 'marine-collection',
        path: 'marine-collection-1bedroom',
        title: 'alojamiento.marineCollection.1bedroom.title',
        subtitle: 'alojamiento.marineCollection.1bedroom.subtitle',
        name: 'alojamiento.marineCollection.1bedroom.name',
        coverImage: 'alojamiento_marinecollection_1bedroom.jpg',
        map: 'map2',
        desc: 'alojamiento.marineCollection.1bedroom.desc',
        sections: [
          {
            title: '',
            size: 'alojamiento.marineCollection.1bedroom.size',
            amenities: [
              'cama_king',
              'caja_seguridad',
              'cocina',
              'tv45',
              'bano_completo',
              'terraza',
              'vista_mar',
              'vista_jardin',             
            ],
          }
        ],
        gallery: [
          'alojamiento_marine_collection_1bedroom_1.jpg',
          'alojamiento_marine_collection_1bedroom_2.jpg',
          'alojamiento_marine_collection_1bedroom_3.jpg',
          'alojamiento_marine_collection_1bedroom_4.jpg',
          'alojamiento_marine_collection_1bedroom_5.jpg',
          'alojamiento_marine_collection_1bedroom_6.jpg',
          'alojamiento_marine_collection_1bedroom_7.jpg',
        ],
        slideImages: [
          'alojamiento_marine_collection_1bedroom_1.jpg',
          'alojamiento_marine_collection_1bedroom_2.jpg',
          'alojamiento_marine_collection_1bedroom_3.jpg',
          'alojamiento_marine_collection_1bedroom_4.jpg',
          'alojamiento_marine_collection_1bedroom_5.jpg',
          'alojamiento_marine_collection_1bedroom_6.jpg',
          'alojamiento_marine_collection_1bedroom_7.jpg',
        ],
        floorPlans: [
          'alojamiento_marine_collection_1bedroom_fp_1.jpg',
        ],
    },
    {
        section: 'marine-collection',
        path: 'marine-collection-studio',
        title: 'alojamiento.marineCollection.studio.title',
        subtitle: 'alojamiento.marineCollection.studio.subtitle',
        name: 'alojamiento.marineCollection.studio.name',
        coverImage: 'alojamiento_marinecollection_studio.jpg',
        map: 'map2',
        desc: 'alojamiento.marineCollection.studio.desc',
        sections: [
          {
            title: '',
            size: 'alojamiento.marineCollection.studio.size',
            amenities: [
              'tv50',
              'cocina_comedor',
              'bano_completo',
              'cama_king',
              'caja_seguridad',
            ],
          }
        ],
        gallery: [
          'alojamiento_marine_collection_studio_1.jpg',
          'alojamiento_marine_collection_studio_2.jpg',
          'alojamiento_marine_collection_studio_3.jpg',
        ],
        slideImages: [
          'alojamiento_marine_collection_studio_1.jpg',
          'alojamiento_marine_collection_studio_2.jpg',
          'alojamiento_marine_collection_studio_3.jpg',
        ],
        floorPlans:
        [
          'alojamiento_marine_collection_studio_fp_1.jpg',
        ],
    },
    {
      section: 'marine-collection',
      path: 'marine-collection-2bedroom',
      title: 'alojamiento.marineCollection.2bedroom.title',
      subtitle: 'alojamiento.marineCollection.2bedroom.subtitle',
      name: 'alojamiento.marineCollection.2bedroom.name',
      coverImage: 'alojamiento_marine_collection_2bedroom.jpg',
      map: 'map2',
      desc: 'alojamiento.marineCollection.2bedroom.desc',
      sections: [
        {
          title: '',
          size: 'alojamiento.marineCollection.2bedroom.size',
          amenities: [
            'cama_king',
            'tv45',
            'caja_seguridad',
            'cocina',
            'cocina_comedor',
            'lockoff',
          ],
        }
      ],
      gallery: [
        'alojamiento_marine_collection_2bedroom_1.jpg',
        'alojamiento_marine_collection_2bedroom_2.jpg',
        'alojamiento_marine_collection_2bedroom_3.jpg',
        'alojamiento_marine_collection_2bedroom_4.jpg',
        'alojamiento_marine_collection_2bedroom_5.jpg',
        'alojamiento_marine_collection_2bedroom_6.jpg',
        'alojamiento_marine_collection_2bedroom_7.jpg',
        'alojamiento_marine_collection_2bedroom_8.jpg',
        'alojamiento_marine_collection_2bedroom_9.jpg',
        'alojamiento_marine_collection_2bedroom_10.jpg',
      ],
      slideImages: [
        'alojamiento_marine_collection_2bedroom_1.jpg',
        'alojamiento_marine_collection_2bedroom_2.jpg',
        'alojamiento_marine_collection_2bedroom_3.jpg',
        'alojamiento_marine_collection_2bedroom_4.jpg',
        'alojamiento_marine_collection_2bedroom_5.jpg',
        'alojamiento_marine_collection_2bedroom_6.jpg',
        'alojamiento_marine_collection_2bedroom_7.jpg',
        'alojamiento_marine_collection_2bedroom_8.jpg',
        'alojamiento_marine_collection_2bedroom_9.jpg',
        'alojamiento_marine_collection_2bedroom_10.jpg',
      ],
      floorPlans:
      [
        'alojamiento_marine_collection_2bedroom_fp_1.jpg',
      ],
  },
    {
        section: 'marine-collection',
        path: 'marine-collection-penthouse',
        title: 'alojamiento.marineCollection.penthouse.title',
        subtitle: 'alojamiento.marineCollection.penthouse.subtitle',
        name: 'alojamiento.marineCollection.penthouse.name',
        coverImage: 'alojamiento_marine_collection_penthouse.jpg',
        map: 'map2',
        desc: 'alojamiento.marineCollection.penthouse1.desc',
        sections: [
          {
            title: 'alojamiento.marineCollection.penthouse1.title',
            icon: 'planta_baja',
            size: 'alojamiento.marineCollection.penthouse1.size',
            amenities: [
              'cama_king',
              'cocina',
              'tv45',
              'vista_mar',
              'terraza_techada',
              'tv50',
              'frigobar',
              'medio_bano',
              'cocktail_pool',
              'camastro',
              'comedor_exterior',
              'grill',
            ],
          },
          {
            title: 'alojamiento.marineCollection.penthouse2.title',
            icon: 'planta_alta',
            size: 'alojamiento.marineCollection.penthouse2.size',
            amenities: [
              'cama_king',
              'cocina',
              'tv45',
              'vista_mar',
              'terraza_techada',
              'tv50',
              'frigobar',
              'medio_bano',
              'cocktail_pool',
              'camastro',
              'comedor_exterior',
              'grill',
            ],
          }
        ],
        gallery: [
          'alojamiento_marine_collection_penthouse_1.jpg',
          'alojamiento_marine_collection_penthouse_2.jpg',
          'alojamiento_marine_collection_penthouse_3.jpg',
          'alojamiento_marine_collection_penthouse_4.jpg',
          'alojamiento_marine_collection_penthouse_5.jpg',
          'alojamiento_marine_collection_penthouse_6.jpg',

          'alojamiento_marine_collection_1bedroom_1.jpg',
          'alojamiento_marine_collection_1bedroom_2.jpg',
          'alojamiento_marine_collection_1bedroom_3.jpg',
          'alojamiento_marine_collection_1bedroom_4.jpg',
          'alojamiento_marine_collection_1bedroom_5.jpg',
          'alojamiento_marine_collection_1bedroom_6.jpg',
          'alojamiento_marine_collection_1bedroom_7.jpg',
        ],
        slideImages: [
          'alojamiento_marine_collection_penthouse_1.jpg',
          'alojamiento_marine_collection_penthouse_2.jpg',
          'alojamiento_marine_collection_penthouse_3.jpg',
          'alojamiento_marine_collection_penthouse_4.jpg',
          'alojamiento_marine_collection_penthouse_5.jpg',
          'alojamiento_marine_collection_penthouse_6.jpg',
        ],
        floorPlans: 
        [
          'alojamiento_marine_collection_penthouse_fp_1.jpg',
        ],
    },
  ];

  // content duplicated for swiper, has to be more than 2 elements
const malabar = [
    {
        section: 'malabar',
        path: 'malabar-floor1',
        coverImage: 'alojamiento_malabar_floor_1.jpg',
        title: 'alojamiento.malabar.floor1.title',
        name: 'alojamiento.malabar.floor1.name',
        subtitle: 'alojamiento.malabar.floor1.subtitle',
        map: 'map3',
        desc: 'alojamiento.malabar.floor1.desc',
        sections: [
          {
            // title: 'alojamiento.malabar.floor1.title',
            size: 'alojamiento.malabar.floor1.size',
            image: 'blank.jpg',
            amenities: [
              'tv45',
              'cocina',
              'terraza',
              'comedor_exterior',
              'deck',
              'grill',
              'alberca',
              'camastro',
              'vista_mar',
              'acceso_alberca',
            ],
          },
        ],
        slideImages: [
          'alojamientos_malabar_floor1_7.jpg',
          'alojamientos_malabar_floor1_1.jpg',
          'alojamientos_malabar_floor1_6.jpg',
          'alojamientos_malabar_floor1_4.jpg',
        ],
        gallery: [
          'alojamientos_malabar_floor1_1.jpg',
          'alojamientos_malabar_floor1_2.jpg',
          'alojamientos_malabar_floor1_3.jpg',
          'alojamientos_malabar_floor1_4.jpg',
          'alojamientos_malabar_floor1_5.jpg',
          'alojamientos_malabar_floor1_6.jpg',
          'alojamientos_malabar_floor1_7.jpg',
        ],
        floorPlans: [
          'alojamientos_malabar_floor1_fl_1.jpg'
        ],
      },
      {
        section: 'malabar',
        path: 'malabar-floor2',
        coverImage: 'alojamiento_malabar_floor_2.jpg',
        title: 'alojamiento.malabar.floor2.title',
        name: 'alojamiento.malabar.floor2.name',
        subtitle: 'alojamiento.malabar.floor2.subtitle',
        map: 'map3',
        desc: 'alojamiento.malabar.floor2.desc',
        sections: [
          {
            // title: 'alojamiento.malabar.floor2.title',
            size: 'alojamiento.malabar.floor2.size',
            image: 'blank.jpg',
            amenities: [
              'terraza',
              'sala_exterior',
              'cocktail_pool',
              'cocina',
              'tv45',
              'tv75',
              'acceso_alberca',
            ],
          },
        ],
        slideImages: [
          'alojamientos_malabar_floor2_5.jpg',
          'alojamientos_malabar_floor2_6.jpg',
          'alojamientos_malabar_floor2_1.jpg',
          'alojamientos_malabar_floor2_9.jpg',
          'alojamientos_malabar_floor2_11.jpg',
          'alojamientos_malabar_floor2_10.jpg',
        ],
        gallery: [
          'alojamientos_malabar_floor2_1.jpg',
          'alojamientos_malabar_floor2_2.jpg',
          'alojamientos_malabar_floor2_3.jpg',
          'alojamientos_malabar_floor2_4.jpg',
          'alojamientos_malabar_floor2_5.jpg',
          'alojamientos_malabar_floor2_6.jpg',
          'alojamientos_malabar_floor2_7.jpg',
          'alojamientos_malabar_floor2_8.jpg',
          'alojamientos_malabar_floor2_9.jpg',
          'alojamientos_malabar_floor2_11.jpg',
          'alojamientos_malabar_floor2_10.jpg',
        ],
        floorPlans: [
          'alojamientos_malabar_floor2_fl_1.jpg'
        ],
      },
    {
        section: 'malabar',
        path: 'malabar-floor1',
        coverImage: 'alojamiento_malabar_floor_1.jpg',
        title: 'alojamiento.malabar.floor1.title',
        name: 'alojamiento.malabar.floor1.name',
        subtitle: 'alojamiento.malabar.floor1.subtitle',
        map: 'map3',
        desc: 'alojamiento.malabar.floor1.desc',
        sections: [
          {
            // title: 'alojamiento.malabar.floor1.title',
            size: 'alojamiento.malabar.floor1.size',
            image: 'blank.jpg',
            amenities: [
              'tv45',
              'cocina',
              'terraza',
              'comedor_exterior',
              'deck',
              'grill',
              'alberca',
              'camastro',
              'vista_mar',
              'acceso_alberca',
            ],
          },
        ],
        slideImages: [
          'alojamientos_malabar_floor1_7.jpg',
          'alojamientos_malabar_floor1_1.jpg',
          'alojamientos_malabar_floor1_6.jpg',
          'alojamientos_malabar_floor1_4.jpg',
        ],
        gallery: [
          'alojamientos_malabar_floor1_1.jpg',
          'alojamientos_malabar_floor1_2.jpg',
          'alojamientos_malabar_floor1_3.jpg',
          'alojamientos_malabar_floor1_4.jpg',
          'alojamientos_malabar_floor1_5.jpg',
          'alojamientos_malabar_floor1_6.jpg',
          'alojamientos_malabar_floor1_7.jpg',
        ]
        
      },
      {
        section: 'malabar',
        path: 'malabar-floor2',
        coverImage: 'alojamiento_malabar_floor_2.jpg',
        title: 'alojamiento.malabar.floor2.title',
        name: 'alojamiento.malabar.floor2.name',
        subtitle: 'alojamiento.malabar.floor2.subtitle',
        map: 'map3',
        desc: 'alojamiento.malabar.floor2.desc',
        sections: [
          {
            // title: 'alojamiento.malabar.floor2.title',
            size: 'alojamiento.malabar.floor2.size',
            image: 'blank.jpg',
            amenities: [
              'terraza',
              'sala_exterior',
              'cocktail_pool',
              'cocina',
              'tv45',
              'tv75',
              'acceso_alberca',
            ],
          },
        ],
        slideImages: [
          'alojamientos_malabar_floor2_5.jpg',
          'alojamientos_malabar_floor2_6.jpg',
          'alojamientos_malabar_floor2_1.jpg',
          'alojamientos_malabar_floor2_9.jpg',
          'alojamientos_malabar_floor2_11.jpg',
          'alojamientos_malabar_floor2_10.jpg',
        ],
        gallery: [
          'alojamientos_malabar_floor2_1.jpg',
          'alojamientos_malabar_floor2_2.jpg',
          'alojamientos_malabar_floor2_3.jpg',
          'alojamientos_malabar_floor2_4.jpg',
          'alojamientos_malabar_floor2_5.jpg',
          'alojamientos_malabar_floor2_6.jpg',
          'alojamientos_malabar_floor2_7.jpg',
          'alojamientos_malabar_floor2_8.jpg',
          'alojamientos_malabar_floor2_9.jpg',
          'alojamientos_malabar_floor2_11.jpg',
          'alojamientos_malabar_floor2_10.jpg',
        ]
        
      },
];

  export default {
    marine,
    malabar,
  };