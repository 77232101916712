import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ButtonBack from '../components/buttonBack';
import SwiperCoverflow, { Slide } from '../components/swiperCoverflow';
import LngSwitch from '../components/lngSwitch';
import { useTranslation, Trans } from 'react-i18next';

// interface lng {
//     nativeName: string,
// };
// const lngs = {
//     en: { nativeName: 'English' },
//     es: { nativeName: 'Español' },
// };

interface Props {
    title: string,
    section: string,
    slides: Array<Slide>
};
const View = (props:Props) => {
    const { t, i18n } = useTranslation();
    let location = useLocation();
    // console.log('STATE', location.state)
    let index = location?.state?.index || 0;
    const fromIndex = location?.state?.index || 0;

    // console.log('props.section', props.section);
    let homeIndex = 0;
    switch (props.section) {
        case 'consumo': homeIndex = 0;
            break;
        case 'destinos': homeIndex = 1;
            break;
        case 'amenities': homeIndex = 3;
            break;
        case 'alojamiento': homeIndex = 2;
            break;
    }
    // console.log('homeIndex', homeIndex);
    let backPath = props.section === 'marine-collection' || props.section === 'malabar' ? '/alojamiento' : '/home';
    if (props.section === 'malabar') {
      // console.log('FROM MA:ABAR')
      index = 3
    }
    // if (props.section === 'destinos') {
    //   backPath = '/destinos';
    // }
    return (
        <div className="container column blue section bgLine">
            <LngSwitch dark/>
            <nav className='row'>
                <div className="alignLeft">

                    {/* {Object.keys(lngs).map((lng) => (
                        <button key={lng} onClick={() => i18n.changeLanguage(lng)} disabled={i18n.resolvedLanguage === lng }>{lng}</button>
                    ))} */}

                    {/* <ButtonBack /> */}
                    <ButtonBack  to={backPath} state={ { index: fromIndex, homeIndex } }/>
                    <h1 className='fontCoconat textWhite font5'>{props.title.toUpperCase()}</h1>
                </div>
                {/* <div className='alignRight'>
                    <button></button>
                </div>                */}
            </nav>
            <SwiperCoverflow
              index={index}
              section={props.section}
              slides={props.slides}
            />
        </div>
    );
};

export default View;
