const Camastro = () => (
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path className="cls-1" d="M82.2,64.65h-14v-4.54l13.7-13.7c.33-.33.36-.83.13-1.22-.04-.07-.07-.14-.13-.2l-3-3s0,0,0,0c-.39-.39-1.02-.39-1.41,0l-12.66,12.66h-5.77c-.45-1.72-2-3-3.86-3h-8c-1.86,0-3.41,1.28-3.86,3h-13.12s-.01,0-.02,0c-.55,0-1,.45-1,1v4h0c0,.13.03.26.08.37,0,0,0,.01,0,.02.15.36.51.61.92.61h1v3.99h-11c-.55,0-1,.45-1,1s.45,1,1,1h62c.55,0,1-.45,1-1s-.45-1-1-1ZM64.2,64.65v-3.99h2v3.99h-2ZM37.2,64.65v-3.99h6v2h0c0,.55.45,1,1,1h8c.13,0,.26-.03.37-.08,0,0,.01,0,.02,0,.36-.15.61-.51.61-.92v-2h9v3.99h-25ZM31.2,58.66v-2h12v2h-12ZM58.18,56.66s.01,0,.02,0h0s.01,0,.02,0h6.35l1,2h-12.37v-2h4.98ZM78.19,44.11l1.59,1.59-12.3,12.3-1.06-2.12,11.77-11.77ZM56.92,54.66h-3.44c.35-.59.98-1,1.72-1s1.37.4,1.72,1ZM47.2,53.66h4.55c-.34.59-.55,1.26-.55,1.99,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0v6h-6v-6c0-1.1.9-2,2-2ZM33.2,60.66h2v3.99h-2v-3.99Z"/>
    <path className="cls-1" d="M36.2,38.65c3.31,0,6-2.69,6-6,0-3.31-2.69-6-6-6s-6,2.69-6,6,2.69,6,6,6ZM36.2,28.65h0c2.21,0,4,1.79,4,4,0,2.21-1.79,4-4,4s-4-1.79-4-4,1.79-4,4-4Z"/>
    <path className="cls-1" d="M36.2,25.65h0c.55,0,1-.45,1-1v-3c0-.55-.45-1-1-1s-1,.45-1,1v3h0c0,.55.45,1,1,1Z"/>
    <path className="cls-1" d="M35.2,40.65v3h0c0,.55.45,1,1,1h0c.55,0,1-.45,1-1v-3c0-.55-.45-1-1-1s-1,.45-1,1Z"/>
    <path className="cls-1" d="M29.84,27.7c.19.19.44.29.71.29h0c.27,0,.52-.11.71-.29.39-.39.39-1.02,0-1.41l-2.12-2.12c-.39-.39-1.02-.39-1.41,0-.39.39-.39,1.02,0,1.41l2.12,2.12Z"/>
    <path className="cls-1" d="M41.15,37.6c-.39.39-.39,1.02,0,1.41l2.12,2.12c.19.19.44.29.71.29h0c.27,0,.52-.11.71-.29.39-.39.39-1.02,0-1.41l-2.12-2.12c-.39-.39-1.02-.39-1.41,0Z"/>
    <path className="cls-1" d="M25.2,33.65h3c.55,0,1-.45,1-1s-.45-1-1-1h-3c-.55,0-1,.45-1,1s.45,1,1,1Z"/>
    <path className="cls-1" d="M43.2,32.65c0,.55.45,1,1,1h3c.55,0,1-.45,1-1s-.45-1-1-1h-3c-.55,0-1,.45-1,1Z"/>
    <path className="cls-1" d="M29.84,37.6l-2.12,2.12c-.19.19-.29.44-.29.71,0,.55.45,1,1,1,.27,0,.52-.11.71-.29l2.12-2.12c.39-.39.39-1.02,0-1.41s-1.02-.39-1.41,0Z"/>
    <path className="cls-1" d="M41.86,28c.27,0,.52-.11.71-.29l2.12-2.12c.39-.39.39-1.02,0-1.41s-1.02-.39-1.41,0l-2.12,2.12c-.19.19-.29.44-.29.71,0,.55.45,1,1,1Z"/>
  </svg>
)
export default Camastro;