const Lockoff = () => (
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path className="cls-1" d="M22.52,78.74h54.96c.69,0,1.26-.56,1.26-1.26s-.56-1.26-1.26-1.26h-3.32v-28.45l.42.28c1.72,1.14,2.12,1.4,2.22,1.47h0c.37.25.84.28,1.26.06.42-.22.69-.65.69-1.11v-7.63c0-.42-.22-.82-.56-1.04l-27.48-18.32c-.42-.29-.97-.29-1.38,0l-13.32,8.88v-7.83c0-.69-.56-1.26-1.26-1.26h-7.63c-.69,0-1.26.56-1.26,1.26v14.59l-4.01,2.69c-.35.23-.57.63-.57,1.04v7.63c0,.45.28.89.71,1.12.41.22.86.19,1.23-.07l2.64-1.74v28.45h-3.32c-.69,0-1.26.56-1.26,1.26s.56,1.26,1.26,1.26ZM71.64,76.22H28.36v-30.12l21.64-14.44.15.1,21.49,14.34v30.12ZM28.36,23.78h5.12v8.24l-5.12,3.41v-11.65ZM23.78,46.12v-4.6l26.22-17.49.15.1,26.07,17.39v4.6l-.42-.28c-22.36-14.89-24.83-16.54-25.12-16.74h0c-.41-.28-.95-.28-1.38,0l-25.53,17.01Z"/>
    <path className="cls-1" d="M41.11,63.74c0,4.9,3.99,8.89,8.89,8.89s8.89-3.99,8.89-8.89c0-2.82-1.39-5.51-3.71-7.18l-.11-.08v-14.11c0-.38-.17-.74-.47-.98l-3.82-3.05c-.46-.37-1.11-.37-1.57,0l-3.82,3.05c-.3.24-.47.6-.47.98v3.05c0,.27.09.54.25.76l1.72,2.3-1.72,2.3c-.16.22-.25.48-.25.76v4.95l-.11.08c-2.32,1.67-3.71,4.36-3.71,7.18ZM46.82,58.25c.39-.22.63-.64.63-1.09v-5.22l2.04-2.72c.33-.44.33-1.07,0-1.51l-2.04-2.72v-2.03l2.56-2.05,2.56,2.05v14.19c0,.45.24.86.63,1.09,2,1.16,3.19,3.22,3.19,5.49,0,3.52-2.86,6.38-6.38,6.38s-6.37-2.86-6.37-6.38c0-2.27,1.19-4.32,3.19-5.49Z"/>
    <g>
      <path className="cls-1" d="M48.47,63.74c0,.84.68,1.53,1.53,1.53s1.53-.68,1.53-1.53-.68-1.53-1.53-1.53-1.53.68-1.53,1.53Z"/>
      <path className="cls-2" d="M50,65.54c-.99,0-1.79-.81-1.79-1.8s.81-1.79,1.79-1.79,1.8.81,1.8,1.79-.81,1.8-1.8,1.8ZM50,62.48c-.69,0-1.26.56-1.26,1.26s.56,1.26,1.26,1.26,1.26-.56,1.26-1.26-.56-1.26-1.26-1.26Z"/>
    </g>
  </svg>
)
export default Lockoff;