import useWindowDimensions from '../hooks/useWindowDimensions';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
interface Props {
    hidePopUp: any,
}
const PoUpMap = ({ hidePopUp, name, category = '', text = '', link = '' }:any) => {
    const { height, width } = useWindowDimensions();
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <div className="quickView" style={{
            top: (height / 2) - 200,
            left: (width / 2) - 200,
        }}>
            <button onClick={hidePopUp}>x</button>
            <div className="textAlignCenter justify-content-center">
            <span className="fontZapfino">{category}</span>
            <h4 className="fontCoconat">{name.toUpperCase()}</h4>
            <p className="fontAtak">
                {text.substr(0, 100)}...
            </p>
            <button className="button fontAtak" onClick={() => navigate(link)}>{t('button.detail').toUpperCase()}</button>
            </div>
        </div>
    )
};
export default PoUpMap;