import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { useNavigate } from 'react-router-dom';
import ButtonBack from '../components/buttonBack';
import PopUpMap from '../components/popUpMap';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
interface Spot {
    name: string,
    category: string,
    link: string,
    text?: string,
    x: number,
    y: number,
};

const Home = () => {
    let location = useLocation();
    const { t } = useTranslation();
    // const URL = 'https://raw.githubusercontent.com/img-mapper/react-docs/master/src/assets/example.jpg';
    // const MAP = {
    //     name: 'my-map',
    //     // GET JSON FROM BELOW URL AS AN EXAMPLE AND PUT IT HERE
    //     areas: 'https://raw.githubusercontent.com/img-mapper/react-docs/master/src/assets/example.json',
    // };
    const { height, width } = useWindowDimensions();
    const navigate = useNavigate();

    const [isVisiblePopUp, setIsVisiblePopUp] = useState(false);
    const [currentSpot, setCurrentSpot] = useState<Spot>({
        name: '',
        category: '',
        link: '',
        x: 0,
        y: 0,
    });
    const [isVisibleSection, setIsVisibleSection] = useState(false);

    const mapOriginalHeight = 3164; // original
    const mapOriginalWidth = 1607; // original
    const mapHeight = height;
    const mapWidth = (mapOriginalWidth * height) / mapOriginalHeight;

    const testLobbyX = (mapHeight * 1770) / mapOriginalHeight;
    const testLobbyY = (mapHeight * 1700) / mapOriginalHeight;
    const spotSpaX = (mapHeight * 2600) / mapOriginalHeight;
    const spotSpaY = (mapHeight * 1470) / mapOriginalHeight;
    const testSectionX = (mapHeight * 500) / mapOriginalHeight;
    const testSectionY = (mapHeight * 970) / mapOriginalHeight;

    const sectionHeight = height;
    const sectionWidth = (2725 * height) / 1434;

    const spotsObj: {[key: string]: Spot} = {
        games: {
            name: 'Pasatiempo',
            category: t('map.category.amenities'),
            link: '/amenidades/games',
            text: t('amenities.games.desc'),
            x: (mapHeight * 650) / mapOriginalHeight,
            y: (mapHeight * 1050) / mapOriginalHeight,
        },
    };
    const spots = Object.keys(spotsObj).map((v:any, k:number) => {
        return (
        <circle key={k} onClick={() => showPopUp(spotsObj[v])} className={`mapSpot ${currentSpot.name === spotsObj[v].name ? 'active' : ''}`} r="14" cx={spotsObj[v].x} cy={spotsObj[v].y} >
        </circle>)
    });

    useEffect(() => {
        if (spotsObj[location.state?.path]) {
            setCurrentSpot(spotsObj[location.state?.path]);
            setIsVisiblePopUp(true);
        }
    }, [location.state])

    const showPopUp = (spot:any) => {
        setIsVisiblePopUp(true);
        setCurrentSpot(spot);
    };
    const hidePopUp = () => {
        setIsVisiblePopUp(false);
    };
    const showSection = () => setIsVisibleSection(true);
    return (
        <div className=''>
            <nav
                style={{
                    position: 'fixed',
                    top: 0,
                    width: '100%',
                    textAlign: 'center',
                    zIndex: 99,
                    marginLeft: 50,
                }}
            >
                <ButtonBack black dark />
            </nav>
            {/* <div> */}
            {/* <button className='buttonMenu' onClick={() => navigate("/")}>MENÚ</button> */}
            {/* </div> */}
            {/* <img src="/map.jpg" alt="map" /> */}
            {/* <img useMap="#primary" src="http://placehold.it/350x150" alt="350 x 150 pic"></img>
            <map name="primary">
                <area shape="circle" coords="75,75,75" href="left.html" />
                <area shape="circle" coords="275,75,75" href="right.html" />
            </map> */}

            {/* <img src="/map.jpg" alt="map" /> */}
            {isVisiblePopUp && (
                <PopUpMap hidePopUp={hidePopUp} {...currentSpot} />
            )}

            <div className="mapContainer" style={{ width: isVisibleSection ? sectionWidth : mapWidth }}>
                {!isVisibleSection && <svg id="svg" width={mapWidth} height={mapHeight}>
                    <image id="IMG" className="map" xlinkHref="/assets/map2.jpg" width={mapWidth} height={mapHeight}></image>
                    <circle onClick={() => showPopUp('lobby')} className="polygon" r="14" cx={testLobbyX} cy={testLobbyY} fill="red" >ASDF</circle>
                    <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 60 60" fill="#fff6e5" x={testLobbyX} y={testLobbyY}><path d="M12 0c-4.198 0-8 3.403-8 7.602 0 6.243 6.377 6.903 8 16.398 1.623-9.495 8-10.155 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.342-3 3-3 3 1.343 3 3-1.343 3-3 3z"/></svg>
                    {/* <circle onClick={() => showPopUp('lobby')} className="polygon" r="14" cx={testLobbyX} cy={testLobbyY} fill="red" /> */}
                    {/* <circle onClick={() => showPopUp('spa')} className="polygon" r="14" cx={spotSpaX} cy={spotSpaY} fill="red" /> */}
                </svg>}

                    {/* {isVisibleSection && <svg className="lobby" width={sectionWidth} height={sectionHeight} onClick={() => setIsVisibleSection(false)}>
                        <image id="IMG2" xlinkHref="/map/lobby.jpg" width={sectionWidth} height={sectionHeight}></image>
                    </svg>} */}
            </div>
            

            
            {/* <ImageMapper src={URL} map={MAP} />; */}
        </div>
    );
};

export default Home;
