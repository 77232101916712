const Recamara = () => (
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <g>
      <path className="cls-1" d="M24.55,79.53h-3.9v-2.93c0-.54-.44-.98-.98-.98h-1.95v-23.42h1.95v4.88c0,.54.44.98.98.98s.98-.44.98-.98v-4.88h.98c.62,0,1.09-.61.95-1.21l-1.95-7.81c-.11-.44-.5-.74-.95-.74h-6.83c-.41,0-.77.25-.92.63,0,0-2.93,7.8-2.93,7.81-.24.63.25,1.32.92,1.32h4.88v23.42h-1.95c-.54,0-.98.44-.98.98v2.93h-1.95c-.54,0-.98.44-.98.98l1.95.98h73.81l1.95-.98c0-.54-.44-.98-.98-.98h-1.95M12.29,50.25l2.2-5.86h5.4l1.46,5.86h-9.06ZM18.69,79.53h-3.9v-1.95h3.9v1.95ZM28.45,79.53h-1.95M38.21,79.53,40.16,79.53"/>
      <path className="cls-1" d="M65.27,25.12c0-.48-.39-.87-.87-.87h-1.57l-7.47-7.47c-.33-.33-.9-.33-1.23,0l-7.47,7.47h-1.57c-.48,0-.87.39-.87.87v9.66c0,.48.39.87.87.87h19.32c.48,0,.87-.39.87-.87v-9.66ZM54.74,18.63l5.63,5.63h-11.26l5.63-5.63ZM63.53,33.91h-17.58v-7.92h17.58v7.92Z"/>
    </g>
    <path className="cls-1" d="M10.88,79.53h1.64v1.95h-1.64c-.54,0-.98-.44-.98-.98h0c0-.54.44-.98.98-.98Z"/>
    <path className="cls-1" d="M85.97,79.53h1.64v1.95h-1.64c-.54,0-.98-.44-.98-.98h0c0-.54.44-.98.98-.98Z" transform="translate(172.61 161.01) rotate(-180)"/>
    <path className="cls-2" d="M81.12,59.73h-.28v-10.21c0-4.74-4.17-8.59-9.3-8.59h-32.71c-5.13,0-9.3,3.85-9.3,8.59v10.21h-.28c-1.7,0-3.07,1.24-3.07,2.77v7c0,1.53,1.38,2.77,3.07,2.77h2.59v7.44c0,.55.45,1,1,1h3.35c.46,0,.86-.31.97-.76l1.89-7.67h32.26l1.89,7.67c.11.45.51.76.97.76h3.35c.55,0,1-.45,1-1v-7.44h2.59c1.7,0,3.07-1.24,3.07-2.77v-7c0-1.53-1.38-2.77-3.07-2.77ZM31.55,49.52c0-3.63,3.27-6.59,7.3-6.59h32.71c4.02,0,7.3,2.96,7.3,6.59v10.21h-3.19v-3.78c0-1.99-1.84-3.61-4.11-3.61h-9.53c-2.27,0-4.11,1.62-4.11,3.61v3.78h-5.43v-3.78c0-1.99-1.84-3.61-4.11-3.61h-9.53c-2.27,0-4.11,1.62-4.11,3.61v3.78h-3.19v-10.21ZM59.91,59.73v-3.78c0-.87.97-1.61,2.11-1.61h9.53c1.14,0,2.11.74,2.11,1.61v3.78h-13.75ZM36.73,59.73v-3.78c0-.87.97-1.61,2.11-1.61h9.53c1.14,0,2.11.74,2.11,1.61v3.78h-13.75ZM35.43,78.7h-1.57v-6.44h3.15l-1.58,6.44ZM76.53,78.7h-1.57l-1.58-6.44h3.15v6.44ZM82.2,69.49c0,.42-.49.77-1.07.77H29.27c-.58,0-1.07-.35-1.07-.77v-7c0-.42.49-.77,1.07-.77h51.85c.58,0,1.07.35,1.07.77v7Z"/>
  </svg>
)
export default Recamara;