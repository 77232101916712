import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ButtonXl from '../components/buttonXL';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
const Home = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const start = (lng:string) => {
        console.log('lng', lng);
        i18n.changeLanguage(lng)
        // navigate('/home');
        window.location.href = `/home?lng=${lng}&index=2`
    };
    return (
        <div className="">
            <video autoPlay muted loop id="myVideo">
                <source src="/video/intro.mov" type="video/mp4" />
            </video>
            <div className="homeControls">
                <img className="logo" src="/assets/logo_2.png" />
                <div className="fontCoconat textWhite textAlignCenter mt20">
                    Choose your language /<br />
                    Elige tu idioma

                </div>
                <div className='lngControls'>
                    <div onClick={() => start('es')}>ES</div>
                    <div onClick={() => start('en')}>EN</div>
                </div>
                
            </div>
            
            
        </div>
    );
};

export default Home;
