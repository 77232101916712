import Alberca from "./alberca";
import Bano from "./bano";
import Caja from "./caja";
import Camastro from "./camastro";
import Cocina from "./cocina";
import Cocktail from "./cocktail";
import Comedor from "./comedor";
import ComedorOut from "./comedorOut";
import Mar from "./mar";
import Sofa from "./sofa";
import Terraza from "./terraza";
import TV from "./tv";
import Cochera from "./cochera";
import Recamara from "./recamara";
import Almacen from "./almacen";
import Lavado from "./lavado";
import Grill from "./grill";
import Deck from "./deck";
import CamaKing from "./camaking";
import CajaSeguridad from "./cajaseguridad";
import Jardin from "./jardin";
import Lockoff from "./lockoff";
import PlantaBaja from "./plantabaja";
import PlantaAlta from "./plantaalta";
import Piso1 from "./piso1";
import Piso2 from "./piso2";
import Piso3 from "./piso3";
import Home from "./home";
import ArrowLeft from "./arrowLeft";
import ArrowRight from "./arrowRight";

export default {
    Alberca,
    Bano,
    Caja,
    Camastro,
    Cocina,
    Cocktail,
    Comedor,
    ComedorOut,
    Mar,
    Sofa,
    Terraza,
    TV,
    Cochera,
    Recamara,
    Almacen,
    Lavado,
    Grill,
    Deck,
    CamaKing,
    CajaSeguridad,
    Jardin,
    Lockoff,
    PlantaAlta,
    PlantaBaja,
    Piso1,
    Piso2,
    Piso3,
    Home,
    ArrowLeft,
    ArrowRight,
};