const Lavado = () => (
  <svg id="Capa_3" data-name="Capa 3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path className="cls-1" d="M67.07,21h-34.15c-2.77,0-5.03,2.25-5.03,5.03v49.97c0,1.65,1.35,3,3,3h38.2c1.65,0,3-1.35,3-3V26.03c0-2.77-2.25-5.03-5.03-5.03ZM32.93,23h34.15c1.67,0,3.03,1.36,3.03,3.03v7.86H29.9v-7.86c0-1.67,1.36-3.03,3.03-3.03ZM69.1,77H30.9c-.55,0-1-.45-1-1v-40.11h40.2v40.11c0,.55-.45,1-1,1Z"/>
    <path className="cls-1" d="M56.06,29.72h9.37c.55,0,1-.45,1-1s-.45-1-1-1h-9.37c-.55,0-1,.45-1,1s.45,1,1,1Z"/>
    <path className="cls-1" d="M46.98,32.06c1.84,0,3.34-1.5,3.34-3.34s-1.5-3.34-3.34-3.34-3.34,1.5-3.34,3.34,1.5,3.34,3.34,3.34ZM46.98,27.37c.74,0,1.34.6,1.34,1.34s-.6,1.34-1.34,1.34-1.34-.6-1.34-1.34.6-1.34,1.34-1.34Z"/>
    <path className="cls-1" d="M36.8,32.06c1.84,0,3.34-1.5,3.34-3.34s-1.5-3.34-3.34-3.34-3.34,1.5-3.34,3.34,1.5,3.34,3.34,3.34ZM36.8,27.37c.74,0,1.34.6,1.34,1.34s-.6,1.34-1.34,1.34-1.34-.6-1.34-1.34.6-1.34,1.34-1.34Z"/>
    <path className="cls-1" d="M50,42.2c-7.83,0-14.2,6.37-14.2,14.2s6.37,14.2,14.2,14.2,14.2-6.37,14.2-14.2-6.37-14.2-14.2-14.2ZM50,68.59c-6.73,0-12.2-5.47-12.2-12.2s5.47-12.2,12.2-12.2,12.2,5.47,12.2,12.2-5.47,12.2-12.2,12.2Z"/>
    <path className="cls-1" d="M50,46.55c-5.43,0-9.85,4.42-9.85,9.85s4.42,9.85,9.85,9.85,9.85-4.42,9.85-9.85-4.42-9.85-9.85-9.85ZM50,64.25c-4.33,0-7.85-3.52-7.85-7.85s3.52-7.85,7.85-7.85,7.85,3.52,7.85,7.85-3.52,7.85-7.85,7.85Z"/>
  </svg>
  )
  export default Lavado;