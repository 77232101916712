import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
import { useNavigate, useLocation } from "react-router-dom";
import AmenityIcon from './amenityIcon';
import { type Swiper as SwiperRef } from 'swiper'
import { useTranslation, Trans } from 'react-i18next';

import 'swiper/css';
// import 'swiper/css/effect-coverflow';
// import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import Icon from './icons';

// export interface Slide {
//     path: String,
//     title: string,
//     subtitle: string,
//     coverImage?: string,
//     link?: string,
// }
interface Props {
    icons: [string],
}

const SwiperIcons = ({ icons }:any) => {
    // console.log('section', section)
    const navigate = useNavigate();
    const _swiper = useRef<SwiperRef>();

    const location = useLocation();
    // const { index } = location.state || { from : '' };
    const { t } = useTranslation();
    const [currentIndex, setCurrentIndex] = useState(0);
    // const [initIndex, setInitIdex] = useState(index || 0);
    const [isSwiperReady, setIsSwiperReady] = useState(false);

    const prev = () => {
      _swiper.current?.slidePrev();
    };
    const next = () => {
      _swiper.current?.slideNext();
    };
    return (
        <div className="amenitiesSwiper">
        {/* <button onClick={test}>sssss</button> */}
        <span className="prev" onClick={prev}><Icon.ArrowLeft /></span>
        <Swiper
            modules={[Navigation]}
            // spaceBetween={0}
            slidesPerView={3}
            // navigation
            style={{ height: 150, width: '100%' }}
            // pagination={{ clickable: true }}
            // scrollbar={{ draggable: true }}
            onSwiper={(swiper:any) => {
                _swiper.current = swiper
                // setIsSwiperReady(true)
            }}
            onSlideChange={() => console.log('slide change')}
            
        >
            {icons.map((v:any, k:number) => (
                <SwiperSlide key={k}>
                    <div className='swiper-slider-content textAlignCenter'>
                        <AmenityIcon name={v} />
                    </div>
                        
                    {/* <button>asdf</button> */}
                </SwiperSlide>
            ))}
        </Swiper>
        <span className="next" onClick={next}><Icon.ArrowRight /> </span>
        </div>
    );
};

export default SwiperIcons;
