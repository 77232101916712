import React, { useState, useEffect } from 'react';
import ButtonBack from '../components/buttonBack';
import Masonry from 'react-masonry-css'
import { useTranslation } from 'react-i18next';

// import Swiper from 'swiper';

// import Swiper styles
// import { Navigation, Pagination } from 'swiper/modules';
// import required modules
import { EffectCoverflow, Pagination } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import { Swiper, SwiperSlide } from 'swiper/react';
import { t } from 'i18next';

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const View = (props:any) => {
    const { t, i18n } = useTranslation();
    const [selectedTabKey, setSelectedTabKey] = useState('image');
    let items = [];
    if (selectedTabKey === 'image') {
      items = props.gallery;
    } else if (selectedTabKey === 'video') {
      items = props.galleryVideo;
    } else if (selectedTabKey === 'floorPlans') {
      items = props.floorPlans;
    }
    const folderPath = selectedTabKey === 'video' ? 'video' : 'assets';
    console.log('items', items);
    return (
        <div className="container column pH80">
            <nav>
                <ButtonBack black />
                <div className="row justify-space-between mb40 align-items-flex-end">
                    <h1 className="fontCoconat font6 textLeft mr40 ">{t('gallery.title').toUpperCase()}</h1>
                    <div className='line'></div>
                    <div>
                        <button onClick={() => setSelectedTabKey('image')} className={`buttonTab ${selectedTabKey === 'image' ? 'active' : ''}`}>{t('gallery.photos')}</button>
                        {props.galleryVideo && <button onClick={() => setSelectedTabKey('video')} className={`buttonTab ${selectedTabKey === 'video' ? 'active' : ''}`}>{t('gallery.video')}</button>}
                        {props.floorPlans && <button onClick={() => setSelectedTabKey('floorPlans')} className={`buttonTab ${selectedTabKey === 'floorPlans' ? 'active' : ''}`}>{t('gallery.floorPlans')}</button>}
                    </div>
                </div>
                <div className=''>
                <Masonry
                    breakpointCols={3}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column">
                    {items.map((v:any, k:number) => (
                        <div className="masonry-item" key={k}>
                            <Zoom classDialog="custom-zoom">
                            <img src={`/${folderPath}/${v}${selectedTabKey === 'video' ? '.gif' : ''}`} />
                            </Zoom>
                        </div>
                    ))}
                </Masonry>
                </div>
            </nav>
            
        </div>
    );
};

export default View;
