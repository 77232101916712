const Comedor = () => (
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <g id="plates">
      <path className="cls-1" d="M50.21,57.38c-4.9,0-8.88-3.98-8.88-8.88s3.98-8.88,8.88-8.88,8.88,3.98,8.88,8.88-3.98,8.88-8.88,8.88ZM50.21,40.72c-4.29,0-7.77,3.49-7.77,7.77s3.49,7.77,7.77,7.77,7.77-3.49,7.77-7.77-3.49-7.77-7.77-7.77Z"/>
      <path className="cls-1" d="M50.21,67.79c-10.64,0-19.29-8.66-19.29-19.29s8.66-19.29,19.29-19.29,19.29,8.66,19.29,19.29-8.66,19.29-19.29,19.29ZM50.21,31.2c-9.54,0-17.29,7.76-17.29,17.29s7.76,17.29,17.29,17.29,17.29-7.76,17.29-17.29-7.76-17.29-17.29-17.29Z"/>
      <path className="cls-1" d="M25.24,70.63c-1.42,0-2.57-1.15-2.57-2.57v-27.6c0-.49-.2-.96-.54-1.31-.72-.72-1.13-1.71-1.13-2.72v-4.54c0-.55.45-1,1-1s1,.45,1,1v4.54c0,.49.2.96.54,1.31.72.72,1.13,1.71,1.13,2.72v27.6c0,.32.26.57.57.57s.57-.26.57-.57v-27.6c0-1,.4-1.98,1.1-2.7,0,0,.02-.02.03-.03.34-.34.54-.82.54-1.31v-4.54c0-.55.45-1,1-1s1,.45,1,1v4.54c0,1-.4,1.98-1.1,2.7,0,0-.02.02-.03.03-.34.34-.54.82-.54,1.31v27.6c0,1.42-1.15,2.57-2.57,2.57Z"/>
      <path className="cls-1" d="M22,32.89c-.55,0-1-.45-1-1v-5.84c0-.55.45-1,1-1s1,.45,1,1v5.84c0,.55-.45,1-1,1Z"/>
      <path className="cls-1" d="M28.49,32.89c-.55,0-1-.45-1-1v-5.84c0-.55.45-1,1-1s1,.45,1,1v5.84c0,.55-.45,1-1,1Z"/>
      <path className="cls-1" d="M25.24,34.85c-.55,0-1-.45-1-1v-7.79c0-.55.45-1,1-1s1,.45,1,1v7.79c0,.55-.45,1-1,1Z"/>
      <path className="cls-1" d="M76.31,70.63c-1.48,0-2.69-1.21-2.69-2.69v-19.44c0-1.34-.22-2.66-.65-3.92-.62-1.83-.94-3.74-.94-5.67v-1.04c0-4.69,1.92-9.26,5.26-12.54.29-.28.72-.36,1.09-.21.37.16.61.52.61.92v41.89c0,1.48-1.21,2.69-2.69,2.69ZM77,28.75c-1.9,2.64-2.96,5.85-2.96,9.13v1.04c0,1.71.28,3.4.83,5.02.5,1.47.76,3.01.76,4.57v19.44c0,.38.31.69.69.69s.69-.31.69-.69V28.75Z"/>
    </g>
  </svg>
)
export default Comedor;