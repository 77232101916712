import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

interface State {
    homeIndex?: number,
    index? : number,
}
interface Props {
    black?: Boolean,
    dark?: Boolean,
    to?: String,
    state?: State,
    mb?: Boolean,
};
const _state = { homeIndex: 0, index: 0 };
const ButtonBack = ({ black = false, dark = false, to = '', state = _state, mb = true }) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const navigateTo = () => {
        if (to === '/home') {
            const homeIndex = state.homeIndex || 0;
            window.location.href=`/home?lng=${i18n.resolvedLanguage}&index=${homeIndex}`;
        } else {
            navigate(to, { state })
        }
    };

    if (to !== '') {
        return (
            <div className={`backButton ${mb === true && 'mb60'} ${black ? 'black' : ''} ${dark ? 'dark' : ''}`} onClick={() => navigateTo()}>
                <i className={`${black ? 'iconBackBlack' : 'iconBack'}`}></i> <span className="fontAtak">{t('button.back').toUpperCase()}</span>
            </div>    
        )
    }
    return (
        <div className={`backButton ${mb === true && 'mb60'} ${black ? 'black' : ''} ${dark ? 'dark' : ''}`} onClick={() => navigate(-1)}>
            <i className={`${black ? 'iconBackBlack' : 'iconBack'}`}></i> <span className="fontAtak">{t('button.back').toUpperCase()}</span>
        </div>
        // <button onClick={() => navigate(-1)}>go back</button>
    );
};

export default ButtonBack;
