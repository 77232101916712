import i18next from "i18next";
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'

i18next 
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        // debug: true,
        fallbackLng: 'en',
        resources: {
            en: {
                translation: {
                    // icons
                    'icon.bedroom': 'Bedroom',
                    'icon.garage': 'Garage',
                    'icon.tv': 'Smart TV',
                    'icon.kitchen': 'Kitchen',
                    'icon.storageRoom': 'Storage room',
                    'icon.laundry': 'Laundry area',
                    'icon.terrace': 'Terrace',
                    'icon.livingRoom': 'Living room',
                    'icon.swimmingPool': 'Swimming pool',
                    'icon.sunLoungers': 'Sun loungers',
                    'icon.outdoorDining': 'Outdoor dining area',
                    'icon.grill': 'Grill',
                    'icon.cocktailPool': 'Cocktail Pool',
                    'icon.tv45': 'Smart TV 45”',
                    'icon.tv75': 'Smart TV 75”',
                    'icon.tv50': 'Smart TV 50”',
                    'icon.poolAccess': 'Main swimming pool',
                    'icon.deck': 'Wooden deck',
                    'icon.oceanView': 'Ocean view',
                    'icon.outdoorLounge': 'Outdoor lounge',
                    'icon.coveredTerrace': 'Covered terrace',
                    'icon.miniFridge': 'Mini fridge',
                    'icon.kingSizeBed': 'King Size bed',
                    'icon.safeBox': 'Safe box',
                    'icon.fullBathroom': 'Full bathroom',
                    'icon.gardenView': 'Garden View',
                    'icon.kitchenDining': 'Kitchen-dining area',
                    'icon.lockoff': 'Lockoff',
                    'icon.halfBathroom': '½ Bathroom',

                    'gallery.title': 'Gallery',
                    'gallery.photos': 'Photos',
                    'gallery.video': 'Video',
                    'gallery.floorPlans': 'Floor plans',
                    'home': 'Home',
                    // MAP CATEGORIES
                    'map.category.amenities': 'Amenities',
                    'map.category.accomodations': 'Accommodations',
                    'map.category.gastronomy': 'Gastronomy',
                    'map.category.destinations': 'Destination',

                    // MAIN SLIDES
                    'slides.consumo.title': '',
                    'slides.consumo.subtitle': 'Gastronomy',
                    'slides.destinos.title': '',
                    'slides.destinos.subtitle': 'Destination',
                    'slides.amenities.title': '',
                    'slides.amenities.subtitle': 'Amenities',
                    'slides.alojamiento.title': '',
                    'slides.alojamiento.subtitle': 'Accommodations',

                    // BUTTONS
                    'button.gallery': 'Gallery',
                    'button.prev': 'Prev',
                    'button.next': 'Next',
                    'button.back': 'Back',
                    'button.location': 'Location',
                    'button.gallery': 'Gallery',
                    'button.detail': 'Open detail',
        
                    // LABELS
                    'label.size': 'Size',
                    'label.characteristics': 'Characteristics',
                    'label.amenities': 'Amenities',
        
                    // AREAS DE COSUMO
                    'consumo': 'Gastronomy',
        
                    'consumo.restaurante.title': 'Restaurant',
                    'consumo.restaurante.subtitle': 'La Palma',
                    'consumo.restaurante.name': 'Restaurant La Palma',
                    'consumo.restaurante.desc': 'Open Monday to Sunday, from 8 am to 9 pm.\
                    Restaurant Gourmet Cuisine\
                    Our chef works with the freshest ingredients, ideal for creating a diverse culinary experience that showcases the best flavors of Mexico. \
                    Enjoy a romantic atmosphere during dinner, accompanied by ambient lighting among palapas and hanging lamps for an unparalleled experience.\
                    ',
                    
                    'consumo.deck.title': 'Deck',
                    'consumo.deck.subtitle': 'La Palma',
                    'consumo.deck.name': 'La Palma Deck',
                    'consumo.deck.desc': 'Open Monday to Sunday, from 8 am to 9 pm.\
                    Experience a serene atmosphere with stunning ocean views and Faro Grill! Surrounded by thoughtfully curated greenery that fosters a connection with nature, elevate your dining experience with breathtaking sunset views across the bay!',
                    
                    'consumo.snack.title': 'Snack',
                    'consumo.snack.subtitle': 'Bar',
                    'consumo.snack.name': 'Snack Bar',
                    'consumo.snack.desc': 'Discover an amazing outdoor space nestled among lush foliage beneath the grandeur of towering parota trees. \
                     Indulge in premium beverages and delectable bites while taking a break from the mundane and inhaling the crisp, refreshing air!\
                    ',
        
                    'consumo.palapa.title': 'Palapa',
                    'consumo.palapa.subtitle': 'Bar',
                    'consumo.palapa.name': 'Palapa Bar',
                    'consumo.palapa.desc': 'Come and enjoy our expertly crafted drinks and delicious snacks while watching your favorite sporting events at our location right next to the outdoor Jacuzzi. \
                    Our main pool and sea-view loungers provide the perfect setting for your relaxation.\
                    ',
                    
                    'consumo.faro.title': 'Faro',
                    'consumo.faro.subtitle': 'Grill',
                    'consumo.faro.name': 'Faro Grill',
                    // 'consumo.faro.desc': 'Welcome to our restaurant on the beautiful beach of Kovay Gardens! We pride ourselves on offering a relaxed yet refined atmosphere where you can savor the sea flavors. Our menu features a variety of dishes made with the freshest local ingredients, ensuring a feast for the senses with every bite. Come join us for a delightful dining experience!',
                    'consumo.faro.desc': 'Where casual elegance meets coastal charm.\
                    Located right on the water\'s edge at Kovay Gardens Beach, our restaurant invites you to relax and enjoy the flavors of the sea in a refined atmosphere. Our menu features a delicious variety of dishes made with the freshest local ingredients, guaranteeing a feast for the senses in every bite.',
        
                    // DESTINOS
                    'destinos': 'Destination',
        
        
                    'destinos.laCruz.title': 'Discover',
                    'destinos.laCruz.subtitle': 'La Cruz',
                    'destinos.laCruz.name': 'La Cruz',
                    'destinos.laCruz.desc': 'Come and discover the authentic heart of Mexico in La Cruz de Huanacaxtle! This picturesque fishing village embraces modernity while preserving its traditional essence. The vibrant marina is the jewel in the crown, hosting cultural events and the famous Mercado del Mar, where restaurants from all over the bay select the freshest seafood. And the best part? Don\'t miss out on this amazing opportunity to experience the beauty of Mexico! Kovay Vacations is just a peaceful 10 to 15-minute walk away from this coastal wonder.',
        
                    'destinos.bucerias.title': 'Discover',
                    'destinos.bucerias.subtitle': 'Bucerías',
                    'destinos.bucerias.name': 'Bucerías',
                    'destinos.bucerias.desc': 'Welcome to Bucerías! This charming town has successfully blended growth with tradition, making it a must-visit destination. Take a stroll through the "Golden Zone" and experience the perfect mix of old-world charm and culinary innovation. The vibrant scene is sure to excite even the most adventurous palate. Come and experience the magic of Bucerías! The colorful facades reflect the rich Mexican architecture, inviting you to explore further. As night falls, the "art walk" transforms the streets into a living canvas, with art galleries opening their doors to the rhythm of live music.',
        
                    'destinos.vallarta.title': 'Discover',
                    'destinos.vallarta.subtitle': 'Puerto Vallarta',
                    'destinos.vallarta.name': 'Puerto Vallarta',
                    'destinos.vallarta.desc': 'Puerto Vallarta It\'s a destination where every corner tells a story, offering an unforgettable experience that combines nature, culture, and tradition. Welcome to the city! The Malecón, a lively stage for artists and statues, invites you to stroll with the Pacific Ocean in the background. And don\'t forget to visit the iconic church, a symbol of the spiritual heritage that stands majestically in the heart of the city. Plus, the airport is just 30 minutes away from Kovay Vacations, making it easy to connect with this gem of the Mexican Pacific.',
        
                    // ALOJAMIENTO
                    'alojamiento': 'Accommodations',
                    'alojamiento.marineCollection.title': 'Marine',
                    'alojamiento.marineCollection.subtitle': 'Collection',
                    'alojamiento.marineCollection.name': 'Marine Collection',
                    
                    // ---------- 1 BEDROOM
                    'alojamiento.marineCollection.1bedroom.title': 'Marine Collection',
                    'alojamiento.marineCollection.1bedroom.subtitle': '1 BEDROOM',
                    'alojamiento.marineCollection.1bedroom.name': 'Marine Collection - 1 BEDROOM',
                    'alojamiento.marineCollection.1bedroom.desc': 'This unit it\'s the perfect choice for those seeking adventure in the beautiful destinations near Kovay Vacation. After a long day of exploring, you can rest easy knowing that you have a safe and comfortable space to return to. Our unit offers convenient amenities to help you relax and recharge, and the views from our terraces are simply breathtaking. Whether you prefer a view of the garden, pool, or bay, we\'ve got you covered!',
                    'alojamiento.marineCollection.1bedroom.size': '140.00 m2 –1,506.95 sqft',
        
                    'alojamiento.marineCollection.1bedroom._desc': 'King Size Bed, Living Room with Smart TV 45", Safe Box. Full Bathroom, con conexión a Recamara principal y a Estancia.\
                    Comedor, Cocina 100% Equipada con Microondas, Estufa Eléctrica,\
                    Refrigerador, Licuadora, Cafetera y Tostador.\
                    Sala con Smart TV de 50 pulgadas y Terraza con vista al jardín o al mar.',
                    // --------- STUDIO
                    'alojamiento.marineCollection.studio.title': 'Marine Collection',
                    'alojamiento.marineCollection.studio.subtitle': 'STUDIO',
                    'alojamiento.marineCollection.studio.name': 'Marine Collection - STUDIO',
                    'alojamiento.marineCollection.studio.size': '49.85 m2 – 536.58 sqft',
                    'alojamiento.marineCollection.studio.desc': 'Welcome to our cozy space, perfect for traveling couples! You\'ll find everything you need for a romantic getaway surrounded by nature and the attractions of beautiful Puerto Vallarta. Rest assured that your vacation will be ideal and unforgettable.',
        
                    // --------- 2 BEDROOM
                    'alojamiento.marineCollection.2bedroom.title': 'Marine Collection',
                    'alojamiento.marineCollection.2bedroom.subtitle': '2 BEDROOM',
                    'alojamiento.marineCollection.2bedroom.name': 'Marine Collection - 2 BEDROOM LOCK-OFF',
                    'alojamiento.marineCollection.2bedroom.size': '190.54 m2 – 2,050.95 sqft',
                    'alojamiento.marineCollection.2bedroom.desc': 'Welcome to your dream vacation home! With ample room and a logical layout, you\'ll have everything you need to make the most of your stay.  Our 1 BD + Studio accommodation is the perfect space for both relaxation and productivity. ',
        
                    // --------- PENTHOUSE OK
                    'alojamiento.marineCollection.penthouse.title': 'Marine Collection',
                    'alojamiento.marineCollection.penthouse.subtitle': '1 BEDROOM PENTHOUSE',
                    'alojamiento.marineCollection.penthouse.name': '1 BEDROOM PENTHOUSE',
                    // planta alta
                    'alojamiento.marineCollection.penthouse1.title': 'Ground Floor',
                    'alojamiento.marineCollection.penthouse1.size': '140.00 m2 –1,506.95 sqft',
                    'alojamiento.marineCollection.penthouse1.desc': 'If you\'re looking for a comfortable and private experience without sacrificing convenience, this accommodation is the ideal option! It provides all the necessary amenities for a comfortable stay. Plus, the  bedroom is connected to the terrace via private stairs, which is super convenient!',
                    // planta baja
                    'alojamiento.marineCollection.penthouse2.title': 'Upper Floor',
                    'alojamiento.marineCollection.penthouse2.size': '141.91 m2 – 1,527.51 sqft',
                
                    // CASA KARMA
                    'alojamiento.karma.title': 'Casa',
                    'alojamiento.karma.subtitle': 'Karma',
                    'alojamiento.karma.name': 'Casa Karma',
                    'alojamiento.karma.desc': 'Welcome to our fully equipped and spacious house, perfect for families and groups seeking privacy without sacrificing comfort! You\'ll love the cozy, fully-equipped house and make it your home away from home.',
                    
                    // 'alojamiento.karma.level1.title': 'Interior groud level',
                    'alojamiento.karma.level1.title': 'Ground Floor',
                    'alojamiento.karma.level1.size': 'Interior: 256.70 m2 – 2,763.10 sqft | Exterior: 285.03 m2 – 3,068.04 sqft',
                                        
                    // 'alojamiento.karma.level2.title': 'Second level',
                    'alojamiento.karma.level2.title': '1st Floor',
                    'alojamiento.karma.level2.size': '274.33 m2 – 2,952.86 sqft',
        
                    // CASA SANTHI
                    'alojamiento.shanti.title': 'Casa',
                    'alojamiento.shanti.subtitle': 'Shanti',
                    'alojamiento.shanti.name': 'Casa Shanti',
                    'alojamiento.shanti.desc': ' Come and experience the breathtaking views and unique atmosphere of this amazing house! With its outdoor dining area and covered terrace, it\'s the perfect place to spend quality time with your loved ones and create unforgettable memories. You\'ll be able to relax and enjoy the ultimate in comfort and luxury.',
        
                    'alojamiento.shanti.level1.title': 'Fisrt level',
                    'alojamiento.shanti.level1.size': 'Interior: 177.20 m2 – 1,907.36 sqft | Exterior: 127.59 m2 – 1,373.37 sqft',                    
                    
                    'alojamiento.shanti.level2.title': 'Second level',
                    'alojamiento.shanti.level2.size': '194.42 m2 – 2,092.72 sqft',
        
                    'alojamiento.shanti.level3.title': 'Third Level',
                    'alojamiento.shanti.level3.size': '131.48 m2 – 1,415.24 sqft',
        
                    // CASA MALABAR
                    'alojamiento.malabar.title': 'Casa',
                    'alojamiento.malabar.subtitle': 'Malabar',
                    'alojamiento.malabar.name': 'Casa Malabar',
        
                    'alojamiento.malabar.floor1.title': 'Casa Malabar',
                    'alojamiento.malabar.floor1.subtitle': 'Ground floor',
                    'alojamiento.malabar.floor1.name': 'Casa Malabar - Ground floor',
                    'alojamiento.malabar.floor1.size': 'Interior: 274.73 m2 – 2,957.17 sqft | Exterior: 104.00 m2 – 1,119.45 sqft',
                    'alojamiento.malabar.floor1.desc': 'Enhance your vacation experience with a fully-equipped house that caters to all your needs! Choose between two levels based on your preferences and enjoy a comfortable space surrounded by nature. This private and convenient space is the best option for you and your companions.',
        
                    'alojamiento.malabar.floor2.title': 'Casa Malabar',
                    'alojamiento.malabar.floor2.subtitle': 'Upper level',
                    'alojamiento.malabar.floor2.name': 'Casa Malabar - Upper level',
                    'alojamiento.malabar.floor2.size': '277.76 m2 – 2,989.78 sqft',
                    'alojamiento.malabar.floor2.desc': 'Enhance your vacation experience with a fully-equipped house that caters to all your needs! Choose between two levels based on your preferences and enjoy a comfortable space surrounded by nature. This private and convenient space is the best option for you and your companions.',
        
                    // EN
                    'alojamiento.marineDiamond.title': 'Marine',
                    'alojamiento.marineDiamond.subtitle': 'Diamond',
                    'alojamiento.marineDiamond.name': 'Marine Diamond',
                    'alojamiento.marineDiamond.desc': 'Experience a modern beach retreat in a 7-level building that seamlessly blends with the surrounding landscape. Enjoy self-contained amenities and easy access to the outdoors. Our motor lobby and first-floor front desk are designed for your comfort and convenience. Our 30 ocean-facing units offer exceptional quality and flexibility. Our upper-level amenities, including pools, an outdoor Jacuzzi, and a sky bar, offer a unique experience with 360° panoramic views. Discover the warmth, elegance, and excellence in every detail of our project!',
                    // Amenidades
                    'amenities.mainPool.title': 'Main',
                    'amenities.mainPool.subtitle': 'Pool',
                    'amenities.mainPool.name': 'Main pool',
                    'amenities.mainPool.desc': 'Our main pool has two sections with a towel station, loungers, and a bar for drinks and food service. It\'s just a few steps away from the beach, so you can enjoy both without any hassle!',
                    
                    'amenities.gardens.title': 'Walkways and',
                    'amenities.gardens.subtitle': 'Gardens',
                    'amenities.gardens.name': 'Walkways and Gardens',
                    'amenities.gardens.desc': 'Explore our beautiful walkways and let yourself be carried away by their charm. You\'ll find two wooden bridges, one with a palapa roof and the other shaded by a large parota tree. Abundant greenery and birdsong will accompany you on each new stroll. Come and enjoy our outdoor lounge surrounded by lush greenery! Our lounge features comfortable sofas and coffee tables with decorative details inspired by the sea, creating the perfect environment to relax and read a book while enjoying the shade of the parotas and the view of the sculptures around.',
                    
                    'amenities.games.title': 'Play',
                    'amenities.games.subtitle': 'Area',
                    'amenities.games.name': 'Play Area',
                    'amenities.games.desc': 'Discover a new way to have fun with our giant Chinese and Spanish checkers boards! They\'re perfect for sharing with the little ones and creating unforgettable moments in a peaceful garden environment. You can rest assured that each game is guaranteed, thanks to the respective rule signs.',
                    
                    'amenities.cabanas.title': '',
                    'amenities.cabanas.subtitle': 'Cabanas',
                    'amenities.cabanas.name': 'Cabanas',
                    'amenities.cabanas.desc': 'Experience a higher level of relaxation in our three cabanas located at the top of the main pool, offering privacy, peace and tranquility with comfortable loungers and armchairs. You\'ll love the individual screens and fans in each cabana for your entertainment. Plus, our two-level wooden deck features comfortable sofas, coffee tables, and loungers, and provides easy access to the beach via a couple of steps. And don\'t forget, food and beverage service is also available!',
        
                    'amenities.beach.title': 'Exclusive',
                    'amenities.beach.subtitle': 'Beach',
                    'amenities.beach.name': 'Exclusive Beach',
                    'amenities.beach.desc': 'Discover your own personal oasis on our beach! You can choose between loungers or a lounge area with coffee tables to relax, and surround yourself with palm trees for natural shade. Don\'t miss the lighthouse located on one of the jetties at Kovay Beach. Finally, enjoy water activities such as kayaking and paddleboarding.',
        
                    'amenities.gym.title': '',
                    'amenities.gym.subtitle': 'GYM',
                    'amenities.gym.name': 'GYM',
                    'amenities.gym.desc': 'We\'re open from 6 am to 6 pm, so you can stay fit and enjoy the beautiful sea view surrounded by planters. It\'s the perfect option for fitness enthusiasts like you!',
        
                    'amenities.pool.title': 'Marine Collection',
                    'amenities.pool.subtitle': 'Pool',
                    'amenities.pool.name': 'Marine Collection Pool',
                    'amenities.pool.desc': 'Welcome to our private oasis! This section is designed for your ultimate enjoyment and relaxation, surrounded by beautiful palm trees. You\'ll find comfortable cabanas, loungers, and tables to make your stay as comfortable as possible.',
        
                    'amenities.poolBar.title': 'Pool',
                    'amenities.poolBar.subtitle': 'Pool Bar',
                    'amenities.poolBar.name': 'Pool Bar',
                    'amenities.poolBar.desc': 'Surrounded by palm trees, this pool area is located at the end of an artificial stream. You\'ll find loungers, a small artificial waterfall, and a pool bar where our skilled mixologist prepares drinks for you to enjoy.',
        
                    'amenities.jacuzzi.title': 'Outdoor',
                    'amenities.jacuzzi.subtitle': 'Jacuzzi',
                    'amenities.jacuzzi.name': 'Outdoor Jacuzzi',
                    'amenities.jacuzzi.desc': 'Select your preferred location to enjoy our jacuzzis. The first one is situated next to the Marine Collection, featuring an artificial beach area, loungers, wooden walkways, and surrounded by gardens under the shade of a large parota tree (exclusive for adults).\
                    The second one is located next to the palapa bar, surrounded by tall gardens and with loungers. Although smaller, it is adjacent to the main pool and the beach (available for all ages).',
                    
                    'amenities.lounge.title': 'Pasatiempo',
                    'amenities.lounge.subtitle': 'Cocktail & Lounge',
                    'amenities.lounge.name': 'Pasatiempo Cocktail & Lounge',
                    'amenities.lounge.desc': 'Availability is limited, so be sure to book your event soon! Our talented mixologist will be on hand to serve you and your guests delicious drinks from our bar, and we have a fantastic menu of food and drinks available for you to choose from. We are thrilled to offer you a private event area, complete with tables, armchairs, and a smart TV. As an added bonus, the space also includes a beautiful terrace area with a stunning gardening wall.',                    
        
                },
            },
            es: {
                translation: {
                    'icon.bedroom': 'Recámara',
                    'icon.garage': 'Cochera',
                    'icon.tv': 'Smart TV',
                    'icon.kitchen': 'Cocina',
                    'icon.storageRoom': 'Almacén',
                    'icon.laundry': 'Área de lavado',
                    'icon.terrace': 'Terraza',
                    'icon.livingRoom': 'Sala',
                    'icon.swimmingPool': 'Alberca',
                    'icon.sunLoungers': 'Camastros',
                    'icon.outdoorDining': 'Comedor Exterior',
                    'icon.grill': 'Grill',
                    'icon.cocktailPool': 'Cocktail Pool',
                    'icon.tv45': 'Smart TV 45”',
                    'icon.tv75': 'Smart TV 75”',
                    'icon.tv50': 'Smart TV 50”',
                    'icon.poolAccess': 'Alberca principal',
                    'icon.deck': 'Deck de madera',
                    'icon.oceanView': 'Vista al mar',
                    'icon.outdoorLounge': 'Sala exterior',
                    'icon.coveredTerrace': 'Terraza techada',
                    'icon.miniFridge': 'Frigobar',
                    'icon.kingSizeBed': 'Cama King Size',
                    'icon.safeBox': 'Caja de seguridad',
                    'icon.fullBathroom': 'Baño completo',
                    'icon.gardenView': 'Vista al jardín',
                    'icon.kitchenDining': 'Cocineta-Comedor',
                    'icon.lockoff': 'Lockoff',
                    'icon.halfBathroom': '½ Baño',

                    'gallery.title': 'Galería',
                    'gallery.photos': 'Fotos',
                    'gallery.video': 'Videos',
                    'gallery.floorPlans': 'Planos',
                    'home': 'Inicio',
                    // MAP CATEGORIES
                    'map.category.amenities': 'Amenidades',
                    'map.category.accomodations': 'Alojamientos',
                    'map.category.gastronomy': 'Consumo',
                    'map.category.destinations': 'Destino',

                    // MAIN SLIDES
                    'slides.consumo.title': 'Centros de',
                    'slides.consumo.subtitle': 'Consumo',
                    'slides.destinos.title': '',
                    'slides.destinos.subtitle': 'Destino',
                    'slides.amenities.title': 'Áreas de',
                    'slides.amenities.subtitle': 'Amenidades',
                    'slides.alojamiento.title': 'Áreas de',
                    'slides.alojamiento.subtitle': 'Alojamiento',

                    // BUTTONS
                    'button.gallery': 'Galería',
                    'button.prev': 'Atrás',
                    'button.next': 'Siguiente',
                    'button.back': 'Atrás',
                    'button.location': 'Ubicación',
                    'button.gallery': 'Galería',
                    'button.detail': 'Ver más',

                    // LABELS
                    'label.size': 'Tamaño',
                    'label.characteristics': 'Características',
                    'label.amenities': 'Amenidades',

                    // AREAS DE COSUMO
                    'consumo': 'Centros de Consumo',

                    'consumo.restaurante.title': 'Restuarante',
                    'consumo.restaurante.subtitle': 'La Palma',
                    'consumo.restaurante.name': 'Restaurante La Palma',
                    'consumo.restaurante.desc': 'Abierto de Lunes a Domingo de 8 am a 9 pm.\
                    Restaurant Gourmet Cuisine\
                    Nuestro chef trabaja con los ingredientes más frescos, ideales para crear una experiencia culinaria diversa que muestra los mejores sabores de México. \
                    Disfruta un ambiente romántico durante la cena, acompañado de una luz envolvente entre palapas y lámparas colgantes para una experiencia sin igual.\
                    ',
                    
                    'consumo.deck.title': 'Deck',
                    'consumo.deck.subtitle': 'La Palma',
                    'consumo.deck.name': 'Deck La Palma',
                    'consumo.deck.desc': 'Abierto de Lunes a Domingo de 8 am a 9 pm.\
                    Disfruta de un relajante espacio con vista al mar y al Faro Grill, rodeado de jardinería cuidadosamente seleccionada para mantener la conexión con la naturaleza. Complementa tu experiencia con vistas al atardecer en la bahía durante la cena.',
                    
                    'consumo.snack.title': 'Snack',
                    'consumo.snack.subtitle': 'Bar',
                    'consumo.snack.name': 'Snack Bar',
                    'consumo.snack.desc': 'Ubicado entre el abundante follaje, descubre un espacio envidiable bajo las majestuosas sombras de los grandes árboles de parota que ofrece experiencias al aire libre con licores de primera calidad e interesantes refrigerios ligeros. ¡Escápate de lo típico y ven a respirar aire fresco!\
                    Disfruta al lado de la alberca de Marine Collection y rodéate de jardinería tropical, bajo un gran árbol de parota. Encuentra la mejor experiencia en el área de mesas para disfrutar los alimentos y bebidas de manera relajada o consume en la barra viendo tu deporte favorito en las pantallas.\
                    ',

                    'consumo.palapa.title': 'Palapa',
                    'consumo.palapa.subtitle': 'Bar',
                    'consumo.palapa.name': 'Palapa Bar',
                    'consumo.palapa.desc': 'Deléitate con bebidas preparadas por nuestro mixólogo experto y complementa tu experiencia con nuestro menú especial de snacks durante los eventos deportivos. \
                    Ubicada al lado del Outdoor Jacuzzi, posee una alberca principal y su propio deck donde podrás disfrutar tus bebidas con la comodidad de sillones con vista al mar.\
                    ',
                    
                    'consumo.faro.title': 'Faro',
                    'consumo.faro.subtitle': 'Grill',
                    'consumo.faro.name': 'Faro Grill',
                    'consumo.faro.desc': '“Donde la elegancia casual se encuentra con el encanto costero.\
                    Situado justo en el borde del agua, en la playa de Kovay Gardens, nuestro restaurante te invita a relajarte y disfrutar de los sabores del mar en un ambiente refinado. Nuestro menú presenta una deliciosa variedad de platos elaborados con los ingredientes locales más frescos, garantizando un festín para los sentidos en cada bocado.',

                    // DESTINOS
                    'destinos': 'Destinos',


                    'destinos.laCruz.title': 'Conoce',
                    'destinos.laCruz.subtitle': 'La Cruz',
                    'destinos.laCruz.name': 'La Cruz',
                    'destinos.laCruz.desc': 'Descubre el auténtico corazón de México en La Cruz de Huanacaxtle, un pintoresco pueblo pesquero que conserva su esencia tradicional al tiempo que abraza la modernidad. La joya de la corona es su vibrante marina, un epicentro de eventos culturales y hogar del famoso Mercado del Mar, donde los restaurantes de toda la bahía seleccionan los mariscos más frescos. Desde Kovay Vacations, un tranquilo paseo de 10 a 15 minutos te llevará a esta maravilla costera.',

                    'destinos.bucerias.title': 'Conoce',
                    'destinos.bucerias.subtitle': 'Bucerías',
                    'destinos.bucerias.name': 'Bucerías',
                    'destinos.bucerias.desc': 'Bucerías es un encantador pueblo que ha sabido mezclar magistralmente el crecimiento con la tradición. Pasea por la "Zona Dorada", donde el encanto de lo antiguo se fusiona con la innovación culinaria, presentando una escena vibrante para el paladar aventurero. Las coloridas fachadas reflejan la rica arquitectura mexicana, invitándote a explorar más. Al caer la noche, el "art walk" transforma las calles en un lienzo vivo, con galerías de arte abriendo sus puertas al ritmo de música en vivo.',

                    'destinos.vallarta.title': 'Conoce',
                    'destinos.vallarta.subtitle': 'Puerto Vallarta',
                    'destinos.vallarta.name': 'Puerto Vallarta',
                    'destinos.vallarta.desc': 'Un destino donde cada rincón narra una historia, la ciudad ofrece una experiencia inolvidable que combina naturaleza, cultura y tradición. El malecón, un escenario vivo de artistas y estatuas, te invita a pasear con el Pacífico de fondo. Su icónica iglesia es un símbolo de la herencia espiritual que se alza majestuosamente en el corazón de la ciudad. Y a solo 30 minutos de Kovay Vacations, encontrarás el aeropuerto que te conecta con esta joya del pacífico mexicano.',

                    // ALOJAMIENTO
                    'alojamiento': 'alojamiento',
                    'alojamiento.marineCollection.title': 'Marine',
                    'alojamiento.marineCollection.subtitle': 'Collection',
                    'alojamiento.marineCollection.name': 'Marine Collection',
                    
                    // ---------- 1 BEDROOM
                    'alojamiento.marineCollection.1bedroom.title': 'Marine Collection',
                    'alojamiento.marineCollection.1bedroom.subtitle': '1 BEDROOM',
                    'alojamiento.marineCollection.1bedroom.name': 'Marine Collection - 1 BEDROOM',
                    'alojamiento.marineCollection.1bedroom.desc': 'Esta unidad es ideal para aquellos buscan aventura en los diferentes destinos cercanos a Kovay Vacation, y necesitan un espacio seguro para volver después de un largo día. Disfruta de un descanso reparador y prepárate para iniciar el día con sus convenientes amenidades. Disfruta las increíbles vistas desde sus terrazas que apuntan a la jardinería, alberca o a la bahía dependiendo el nivel en el que esté ubicado.',
                    'alojamiento.marineCollection.1bedroom.size': '140.00 m2 –1,506.95 sqft',

                    'alojamiento.marineCollection.1bedroom._desc': 'Recámara principal con Cama King Size, Smart TV de 45 Pulgadas, Caja de\
                    seguridad. Baño completo con conexión a Recamara principal y a Estancia.\
                    Comedor, Cocina 100% Equipada con Microondas, Estufa Eléctrica,\
                    Refrigerador, Licuadora, Cafetera y Tostador.\
                    Sala con Smart TV de 50 pulgadas y Terraza con vista al jardín o al mar.',
                    // --------- STUDIO
                    'alojamiento.marineCollection.studio.title': 'Marine Collection',
                    'alojamiento.marineCollection.studio.subtitle': 'STUDIO',
                    'alojamiento.marineCollection.studio.name': 'Marine Collection - STUDIO',
                    'alojamiento.marineCollection.studio.size': '49.85 m2 – 536.58 sqft',
                    'alojamiento.marineCollection.studio.desc': 'Un espacio acogedor y equipado perfectamente para parejas viajeras. Aquí encontrarán el descanso ideal para sus vacaciones y disfrutar de una escapada romántica alrededor de la naturaleza y los atractivos de Puerto Vallarta.                    ',

                    // --------- 2 BEDROOM
                    'alojamiento.marineCollection.2bedroom.title': 'Marine Collection',
                    'alojamiento.marineCollection.2bedroom.subtitle': '2 BEDROOM',
                    'alojamiento.marineCollection.2bedroom.name': 'Marine Collection - 2 BEDROOM LOCK-OFF',
                    'alojamiento.marineCollection.2bedroom.size': '190.54 m2 – 2,050.95 sqft',
                    'alojamiento.marineCollection.2bedroom.desc': 'Un espacio diferente para disfrutar de tus vacaciones con comodidad y disponibilidad para crear un experiencia de working vacation sin igual. Un alojamiento que se adapta completamente a tus necesidades gracias a que es un 1 BD + Studio que te brinda más espacio.                    ',

                    // --------- PENTHOUSE OK
                    'alojamiento.marineCollection.penthouse.title': 'Marine Collection',
                    'alojamiento.marineCollection.penthouse.subtitle': '1 BEDROOM PENTHOUSE',
                    'alojamiento.marineCollection.penthouse.name': '1 BEDROOM PENTHOUSE',
                    // planta alta
                    'alojamiento.marineCollection.penthouse1.title': 'Planta baja',
                    'alojamiento.marineCollection.penthouse1.size': '140.00 m2 –1,506.95 sqft',
                    'alojamiento.marineCollection.penthouse1.desc': 'Si buscas una experiencia de confort y privacidad sin sacrificar tu comodidad, este alojamiento es tu opción ideal. Todas las amenidades que necesitas para disfrutar de tu viaje y encontrar un espacio de descanso durante toda tu estancia, además  que se conecta un 1 Bedroom a la terraza a través de escaleras privadas',
                    // planta baja
                    'alojamiento.marineCollection.penthouse2.title': 'Planta alta',
                    'alojamiento.marineCollection.penthouse2.size': '141.91 m2 – 1,527.51 sqft',

                    'alojamiento.marineDiamond.title': 'Marine',
                    'alojamiento.marineDiamond.subtitle': 'Diamond',
                    'alojamiento.marineDiamond.name': 'Marine Diamond',                   

                    // CASA KARMA
                    'alojamiento.karma.title': 'Casa',
                    'alojamiento.karma.subtitle': 'Karma',
                    'alojamiento.karma.name': 'Casa Karma',
                    'alojamiento.karma.desc': 'Un espacio cómodo y amplio, ideal para familias y grupos que busquen privacidad para sus vacaciones sin sacrificar el confort de primer nivel. Disfruta de esta casa totalmente equipada y conviértela en tu hogar lejos de casa.',
                    
                    'alojamiento.karma.level1.title': 'Nivel 1 interior',
                    'alojamiento.karma.level1.size': 'Interior: 256.70 m2 – 2,763.10 sqft | Exterior: 285.03 m2 – 3,068.04 sqft',
                                        
                    'alojamiento.karma.level2.title': 'Nivel 2',
                    'alojamiento.karma.level2.size': '274.33 m2 – 2,952.86 sqft',

                    // CASA SANTHI
                    'alojamiento.shanti.title': 'Casa',
                    'alojamiento.shanti.subtitle': 'Shanti',
                    'alojamiento.shanti.name': 'Casa Shanti',
                    'alojamiento.shanti.desc': 'Disfruta de vistas increíbles y un ambiente sin igual gracias a esta casa con comedor exterior y terraza techada, perfectas para convivir con tus amigos y familiares, a la vez que crean momentos inolvidables donde la relajación y la comodidad serán las estrellas de la tarde.',

                    'alojamiento.shanti.level1.title': 'Nivel 1',
                    'alojamiento.shanti.level1.size': 'Interior: 177.20 m2 – 1,907.36 sqft | Exterior: 127.59 m2 – 1,373.37 sqft',                    
                    
                    'alojamiento.shanti.level2.title': 'Nivel 2',
                    'alojamiento.shanti.level2.size': '194.42 m2 – 2,092.72 sqft',

                    'alojamiento.shanti.level3.title': 'Nivel 3',
                    'alojamiento.shanti.level3.size': '131.48 m2 – 1,415.24 sqft',

                    // CASA MALABAR
                    'alojamiento.malabar.title': 'Casa',
                    'alojamiento.malabar.subtitle': 'Malabar',
                    'alojamiento.malabar.name': 'Casa Malabar',

                    'alojamiento.malabar.floor1.title': 'Casa Malabar',
                    'alojamiento.malabar.floor1.subtitle': 'Planta Baja',
                    'alojamiento.malabar.floor1.name': 'Casa Malabar - Planta Baja',
                    'alojamiento.malabar.floor1.size': 'Interior: 274.73 m2 – 2,957.17 sqft | Exterior: 104.00 m2 – 1,119.45 sqft',
                    'alojamiento.malabar.floor1.desc': 'Lleva tus vacaciones al siguiente nivel con una casa que cuenta con todas las comunidades que necesitas para tu viaje. Elige entre sus dos niveles dependiendo tus preferencias, un espacio cómodo cerca de la naturaleza que brilla gracias a su privacidad y ambiente acogedor. Si buscas un espacio cómodo y alejado, esta es la mejor opción para ti y tus acompañantes',

                    'alojamiento.malabar.floor2.title': 'Casa Malabar',
                    'alojamiento.malabar.floor2.subtitle': 'Planta Alta',
                    'alojamiento.malabar.floor2.name': 'Casa Malabar - Planta Alta',
                    'alojamiento.malabar.floor2.size': '277.76 m2 – 2,989.78 sqft',
                    'alojamiento.malabar.floor2.desc': 'Lleva tus vacaciones al siguiente nivel con una casa que cuenta con todas las comunidades que necesitas para tu viaje. Elige entre sus dos niveles dependiendo tus preferencias, un espacio cómodo cerca de la naturaleza que brilla gracias a su privacidad y ambiente acogedor. Si buscas un espacio cómodo y alejado, esta es la mejor opción para ti y tus acompañantes',

                    // MARINE DIAMOND
                    'alojamiento.marineDiamond.title': 'Marine',
                    'alojamiento.marineDiamond.subtitle': 'Diamond',
                    'alojamiento.marineDiamond.name': 'Marine Diamond',
                    'alojamiento.marineDiamond.desc': 'Sumérgete en un oasis contemporáneo de playa. Nuestro edificio de 7 niveles se funde armoniosamente con el entorno paisajístico, ofreciendo comodidades autónomas y conectividad perfecta entre el interior y el exterior. Desde el motor lobby hasta el front desk en la planta baja, cada espacio está diseñado para fusionar el confort con la naturaleza. Con 30 unidades orientadas hacia el océano, cada una ofrece flexibilidad y calidad excepcional. Además, nuestras amenidades en el nivel superior, como albercas, jacuzzi exterior y sky bar, brindan una experiencia única con vistas panorámicas de 360°. ¡Descubre la calidez, la elegancia y la excelencia en cada detalle de nuestro proyecto!',

                    // Amenidades
                    'amenities.mainPool.title': 'Alberca',
                    'amenities.mainPool.subtitle': 'Principal',
                    'amenities.mainPool.name': 'Alberca principal',
                    'amenities.mainPool.desc': 'Nuestra alberca principal cuenta con dos secciones donde encontrarás una estación de toallas, camastros y una barra para bebidas, además de servicio de alimentos. Su acceso a unos cuantos pasos de la playa te brindará un mayor disfrute.',
                    
                    'amenities.gardens.title': 'Andadores y',
                    'amenities.gardens.subtitle': 'Jardines',
                    'amenities.gardens.name': 'Andadores y Jardines',
                    'amenities.gardens.desc': 'Adéntrate en nuestros bellos  andadores y déjate llevar a través de sus 2 puentes de madera, uno con techo de palapa y el otro bajo la sombra de una gran parota, donde la abundante jardinería y el canto de las aves te acompaña en cada nueva caminata. Disfruta también de su sala exterior rodeada de jardinería, la sala exterior cuenta con sillones y mesas de café, con detalles decorativos que hacen alusión al mar, es un ambiente perfecto para descansar y leer un libro, disfrutando de la sombra de las parotas y la vista a las esculturas que hay alrededor.',
                    
                    'amenities.games.title': 'Área de',
                    'amenities.games.subtitle': 'Juegos',
                    'amenities.games.name': 'Área de Juegos',
                    'amenities.games.desc': 'Redefine tu manera de divertirte con nuestros tableros gigantes de damas chinas y españolas, perfectos para compartir su uso junto a los más pequeños. Crea momentos inolvidables rodeado de jardinería y en un ambiente tranquilo. Cada partida está asegurada  gracias a sus respectivos letreros de reglas.',
                    
                    'amenities.cabanas.title': '',
                    'amenities.cabanas.subtitle': 'Cabanas',
                    'amenities.cabanas.name': 'Cabanas',
                    'amenities.cabanas.desc': 'Conoce un nuevo nivel de descanso gracias a nuestras 3 cabanas en la parte superior de la alberca principal, donde podrás reservar para tener privacidad, paz y tranquilidad mientras te recuestas en sus camastros o sillones. Además cada una cuenta con pantallas y ventiladores individuales para un mayor entretenimiento. Disfruta también del Deck de madera con 2 niveles, cuenta con sillones y mesitas de café, camastros y acceso a playa a través de un par de escalones. Servicio de alimentos y bebidas.',

                    'amenities.beach.title': 'Playa',
                    'amenities.beach.subtitle': 'Exclusiva',
                    'amenities.beach.name': 'Playa Exclusiva',
                    'amenities.beach.desc': 'Encuentra tu oasis personal en nuestra  playa con mini bahía, creada por dos espigones a los lados. Elige entre camastros y un área de sala con mesitas de café para descansar. Rodéate entre palmeras a lo largo de la playa para disfrutar de una sombra natural. No dejes pasar el faro ubicado en uno de los espigones de la playa de Kovay. Por último, y no menos importante, se pueden hacer actividades acuáticas como kayak y paddle.',

                    'amenities.gym.title': '',
                    'amenities.gym.subtitle': 'Gimnasio',
                    'amenities.gym.name': 'Gimnasio',
                    'amenities.gym.desc': 'Abierto de 6 am a 6 pm, ofrecemos una opción diferente para todos aquellos que no se pierden ningún entrenamiento. Mantente en forma y disfruta rodeado de jardineras y una vista al mar sin igual.',

                    'amenities.pool.title': 'Marine Collection',
                    'amenities.pool.subtitle': 'Pool',
                    'amenities.pool.name': 'Marine Collection Pool',
                    'amenities.pool.desc': 'Un espacio ideado para tu disfrute y privacidad donde podrás rodearte de palmeras, y ponerte cómodo con las cabanas, camastros y mesas, que esta sección tiene para ti.',

                    'amenities.poolBar.title': 'Alberca',
                    'amenities.poolBar.subtitle': 'Pool Bar',
                    'amenities.poolBar.name': 'Alberca Pool Bar',
                    'amenities.poolBar.desc': 'Se encuentra al final de un arroyo artificial, rodeada de palmeras. Cuenta con camastros y una pequeña cascada artificial, además de un pool bar donde podrán disfrutar de bebidas preparadas por el mixólogo encargado.',

                    'amenities.jacuzzi.title': 'Jacuzzi',
                    'amenities.jacuzzi.subtitle': 'Exterior',
                    'amenities.jacuzzi.name': 'Jacuzzi Exterior',
                    'amenities.jacuzzi.desc': 'Elige tu ubicación favorita para disfrutar de las burbujas en nuestros jacuzzis. El primero ubicado al lado de Marine Collection, con área de playa artificial, camastros, andadores de madera y rodeado de jardinería bajo la sombra de una gran parota (exclusivo para adultos). \
                    El segundo está ubicado al lado del palapa bar, rodeado por jardinería alta y con camastros. Su área es más pequeña pero está a lado de la alberca principal y la playa (disponible para todas las edades).',
                    
                    'amenities.lounge.title': 'Pasatiempo',
                    'amenities.lounge.subtitle': 'Cocktail & Lounge',
                    'amenities.lounge.name': 'Pasatiempo Cocktail & Lounge',
                    'amenities.lounge.desc': 'Área para eventos privados, sujeto a disponibilidad. Se ofrece un menú de bebidas y alimentos. El espacio cuenta con mesas, sillones y un smart TV, además de una barra para que el mixólogo encargado sirva las bebidas. Tiene un área de terraza con un muro de jardinería.',                    

                },
            }
        }
    })