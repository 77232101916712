const Mar = () => (
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path className="cls-1" d="M82,57.82H20c-.55,0-1,.45-1,1s.45,1,1,1h62c.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M82,53.82H20c-.55,0-1,.45-1,1s.45,1,1,1h62c.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M54,47.82c-.55,0-1,.45-1,1s.45,1,1,1h27c.55,0,1-.45,1-1s-.45-1-1-1h-27Z"/>
    <path className="cls-1" d="M21,42.82c-.55,0-1,.45-1,1s.45,1,1,1h22c.55,0,1-.45,1-1s-.45-1-1-1h-22Z"/>
    <path className="cls-1" d="M20,38.82h62c.55,0,1-.45,1-1s-.45-1-1-1h-16.05c-.52-7.82-7-14-14.95-14s-14.43,6.18-14.95,14h-16.05c-.55,0-1,.45-1,1s.45,1,1,1ZM51,24.82c6.84,0,12.44,5.29,12.95,12h-25.9c.51-6.71,6.11-12,12.95-12Z"/>
    <path className="cls-1" d="M45.63,21.66c.12.44.51.74.96.74.09,0,.17-.01.26-.03.53-.14.85-.69.71-1.23l-1.04-3.86c-.14-.53-.69-.85-1.22-.71-.53.14-.85.69-.71,1.22l1.04,3.86Z"/>
    <path className="cls-1" d="M38.27,26.51c.19.19.44.29.71.29h0c.27,0,.52-.11.71-.29.39-.39.39-1.02,0-1.41l-2.83-2.83c-.39-.39-1.02-.39-1.41,0-.39.39-.39,1.02,0,1.41l2.83,2.83Z"/>
    <path className="cls-1" d="M30.46,33.35l3.86,1.04c.08.02.17.03.26.03.45,0,.85-.3.96-.74.14-.53-.17-1.08-.71-1.23l-3.86-1.04c-.53-.14-1.08.17-1.22.71-.14.53.17,1.08.71,1.22Z"/>
    <path className="cls-1" d="M66.42,33.42c0,.55.45,1,1,1,.09,0,.18-.01.26-.03l3.86-1.04c.53-.14.85-.69.71-1.22-.14-.53-.69-.85-1.22-.71l-3.86,1.04c-.44.12-.74.51-.74.96Z"/>
    <path className="cls-1" d="M63.02,26.8c.27,0,.52-.11.71-.29l2.83-2.83c.38-.39.38-1.02,0-1.41-.39-.39-1.02-.4-1.41,0l-2.83,2.83c-.19.19-.29.44-.29.71,0,.55.45,1,1,1Z"/>
    <path className="cls-1" d="M55.4,22.4c.45,0,.85-.3.96-.74l1.04-3.86c.14-.53-.17-1.08-.71-1.22-.53-.14-1.08.17-1.22.71l-1.04,3.86c-.02.08-.03.17-.03.26,0,.55.45,1,1,1Z"/>
    <path className="cls-1" d="M21.02,60.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M27.02,60.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M33.02,60.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M39.02,60.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M45.02,60.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M51.02,60.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M57.02,60.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M63.02,60.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M69.02,60.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M75.02,60.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M81.02,60.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M24.02,63.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M30.02,63.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M36.02,63.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M42.02,63.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M48.02,63.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M54.02,63.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M60.02,63.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M66.02,63.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M72.02,63.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M78.02,63.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M21.02,66.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M27.02,66.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M33.02,66.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M39.02,66.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M45.02,66.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M51.02,66.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M57.02,66.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M63.02,66.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M69.02,66.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M75.02,66.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M81.02,66.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M24.02,69.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M30.02,69.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M36.02,69.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M42.02,69.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M48.02,69.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M54.02,69.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M60.02,69.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M66.02,69.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M72.02,69.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
    <path className="cls-1" d="M78.02,69.82h0c-.55,0-1,.45-1,1,0,.55.45,1,1,1,.55,0,1-.45,1-1s-.45-1-1-1Z"/>
  </svg>
)
export default Mar;