import i18next from "i18next";


const { t } = i18next;
const consumo = [
    {
      section: 'consumo',
      path: 'restaurante',
      title: 'consumo.restaurante.title',
      subtitle: 'consumo.restaurante.subtitle',
      coverImage: 'consumo_restaurant.jpeg',
      name: 'consumo.restaurante.name',
      desc: 'consumo.restaurante.desc',
      map: 'map3',
      gallery: [
        'consumo_restaurante_1.jpg',
        'consumo_restaurante_2.jpg',
        'consumo_restaurante_3.jpg',
        'consumo_restaurante_4.jpg',
        'consumo_restaurante_5.jpg',
        'consumo_restaurante_6.jpg',
        'consumo_restaurante_7.jpg',
        'consumo_restaurante_8.jpg',
        'consumo_restaurante_9.jpg',
      ],
      slideImages: [
        // 'consumo_restaurante_1.jpg',
        'consumo_restaurante_2.jpg',
        'consumo_restaurante_3.jpg',
      ]
    },
    {
      section: 'consumo',
      path: 'deck',
      title: 'consumo.deck.title',
      subtitle: 'consumo.deck.subtitle',
      name: 'consumo.deck.name',
      desc: 'consumo.deck.desc',
      coverImage: 'consumo_la_palma.jpeg',
      map: 'map3',
      gallery: [
        'consumo_deck_1.jpg',
        'consumo_deck_2.jpg',
        'consumo_deck_3.jpg',
        'consumo_deck_4.jpg',
        'consumo_deck_5.jpg',
      ],
      slideImages: [
        'consumo_deck_1.jpg',
        'consumo_deck_2.jpg',
        'consumo_deck_3.jpg',
        'consumo_deck_4.jpg',
        'consumo_deck_5.jpg',
      ],
    },
    {
      section: 'consumo',
      path: 'snack',
      title: 'consumo.snack.title',
      subtitle: 'consumo.snack.subtitle',
      name: 'consumo.snack.name',
      desc: 'consumo.snack.desc',
      coverImage: 'consumo_snack.jpeg',
      map: 'map2',
      gallery: [
        'consumo_snack_1.jpg',
        'consumo_snack_2.jpg',
        'consumo_snack_3.jpg',
        'consumo_snack_4.jpg',
        'consumo_snack_5.jpg',
        'consumo_snack_6.jpg',
        'consumo_snack_7.jpg',
        'consumo_snack_8.jpg',
        'consumo_snack_9.jpg',
        'consumo_snack_10.jpg',
      ],
      slideImages: [
        'consumo_snack_1.jpg',
        'consumo_snack_2.jpg',
        'consumo_snack_3.jpg',
        'consumo_snack_4.jpg',
        'consumo_snack_5.jpg',
      ],
    },
    {
      section: 'consumo',
      path: 'palapa',
      title: 'consumo.palapa.title',
      subtitle: 'consumo.palapa.subtitle',
      name: 'consumo.palapa.name',
      desc: 'consumo.palapa.desc',
      coverImage: 'consumo_palapa.jpeg',
      map: 'map3',
      gallery: [
        'consumo_palapa_1.jpg',
        'consumo_palapa_2.jpg',
        'consumo_palapa_3.jpg',
        'consumo_palapa_4.jpg',
        'consumo_palapa_5.jpg',
        'consumo_palapa_6.jpg',
      ],
      slideImages: [
        // 'consumo_palapa_1.jpg',
        'consumo_palapa_3.jpg',
        'consumo_palapa_2.jpg',
        'consumo_palapa_4.jpg',
        // 'consumo_palapa_5.jpg',
        // 'consumo_palapa_6.jpg',
      ],
    },
    {
      section: 'consumo',
      path: 'faro',
      title: 'consumo.faro.title',
      subtitle: 'consumo.faro.subtitle',
      name: 'consumo.faro.name',
      desc: 'consumo.faro.desc',
      coverImage: 'consumo_faro.jpeg',
      map: 'map4',
      gallery: [
        'comsumo_faro_1.jpg',
        'comsumo_faro_2.jpg',
        'comsumo_faro_3.jpg',
        'comsumo_faro_4.jpg',
        'comsumo_faro_5.jpg',
        'comsumo_faro_6.jpg',
        'comsumo_faro_7.jpg',
        'comsumo_faro_8.jpg',
        'comsumo_faro_9.jpg',
        'comsumo_faro_11.jpg',
        'comsumo_faro_12.jpg',
        'comsumo_faro_13.jpg',
        'comsumo_faro_14.jpg',
        // 'comsumo_faro_15.jpg',
        'comsumo_faro_16.jpg',
        'comsumo_faro_17.jpg',
        // 'comsumo_faro_18.jpg',
        // 'comsumo_faro_19.jpg',
        'comsumo_faro_20.jpg',
        'comsumo_faro_21.jpg',
        'comsumo_faro_22.jpg',
        // 'comsumo_faro_23.jpg',
        // 'comsumo_faro_24.jpg',
        'comsumo_faro_25.jpg',
        
      ],
      slideImages: [
        // 'comsumo_faro_1.jpg',
        'comsumo_faro_4.jpg',
        'comsumo_faro_2.jpg',
        // 'comsumo_faro_3.jpg',
        'comsumo_faro_5.jpg',
        'comsumo_faro_6.jpg',
        // 'comsumo_faro_7.jpg',
      ],
    },
  ];
const destinos = [
    {
      section: 'destinos',
      path: 'la-cruz',
      title: 'destinos.laCruz.title',
      subtitle: 'destinos.laCruz.subtitle',
      name: 'destinos.laCruz.name',
      desc: 'destinos.laCruz.desc',
      coverImage: 'destinos_la_cruzj.jpg',
      map: 'map5',
      gallery: [
        'destinos_la_cruz_5.gif',
        'destinos_la_cruz_2.jpg',
        'destinos_la_cruz_6.jpg',
        'destinos_la_cruz_4.jpg',
        // 'destinos_la_cruz_3.gif',
        'destinos_la_cruz_4.gif',
        'destinos_la_cruz_5.jpg',
        'destinos_la_cruz_2.gif',
        'destinos_la_cruz_1.jpg',
        'destinos_la_cruz_3.jpg',
        // 'destinos_la_cruz_1.gif',
      ],
      slideImages: [
        'destinos_la_cruz_2.jpg',
        'destinos_la_cruz_4.jpg',
        'destinos_la_cruz_6.jpg',
      ],
    },
    {
      section: 'destinos',
      path: 'bucerias',
      title: 'destinos.bucerias.title',
      subtitle: 'destinos.bucerias.subtitle',
      name: 'destinos.bucerias.name',
      desc: 'destinos.bucerias.desc',
      coverImage: 'destinos_bucerias.jpg',
      map: 'map5',
      gallery: [
        'destinos_bucerias_2.gif',
        'destinos_bucerias_1.jpg',
        'destinos_bucerias_2.jpg',
        'destinos_bucerias_3.jpg',
        'destinos_bucerias_4.jpg',
        'destinos_bucerias_1.gif',
        'destinos_bucerias_5.jpg',
        'destinos_bucerias_6.jpg',
        'destinos_bucerias_7.jpg',
        'destinos_bucerias_4.gif',
      ],
      slideImages: [
        'destinos_bucerias_5.jpg',
        'destinos_bucerias_2.jpg',     
        'destinos_bucerias_6.jpg',
        'destinos_bucerias_7.jpg',
      ]
    },
    {
      section: 'destinos',
      path: 'vallarta',
      title: 'destinos.vallarta.title',
      subtitle: 'destinos.vallarta.subtitle',
      coverImage: 'destinos_puerto_vallarta.jpg',
      name: 'destinos.vallarta.name',
      desc: 'destinos.vallarta.desc',
      map: 'map5',
      slideImages: [
        'destinos_puerto_vallarta_8.jpg',
        'destinos_puerto_vallarta_9.jpg',
        'destinos_puerto_vallarta_6.jpg',
        'destinos_puerto_vallarta_7.jpg',
      ],
      gallery: [
        'destinos_puerto_vallarta_1.jpg',
        'destinos_puerto_vallarta_2.jpg',
        'destinos_puerto_vallarta_3.jpg',
        'destinos_puerto_vallarta_4.jpg',
        'destinos_puerto_vallarta_5.jpg',
        'destinos_puerto_vallarta_6.jpg',
        'destinos_puerto_vallarta_7.jpg',
        'destinos_puerto_vallarta_8.jpg',
        'destinos_puerto_vallarta_9.jpg',
      ],
    },
  ];
const amenities = [
    {
      section: 'amenidades',
      path: 'main-pool',
      title: 'amenities.mainPool.title',
      subtitle: 'amenities.mainPool.subtitle',
      name: 'amenities.mainPool.name',
      desc: 'amenities.mainPool.desc',
      coverImage: 'esparcimiento_mainPool.jpg',
      map: 'map3',
      gallery: [
        'esparcimiento_mainPool_1.jpg',
        'esparcimiento_mainPool_2.jpg',
        'esparcimiento_mainPool_3.jpg',
        'esparcimiento_mainPool_4.jpg',
        'esparcimiento_mainPool_2.gif',
        'esparcimiento_mainPool_5.jpg',
        'esparcimiento_mainPool_6.jpg',
        'esparcimiento_mainPool_7.jpg',
        'esparcimiento_mainPool_8.jpg',
        'esparcimiento_mainPool_1.gif',
        'esparcimiento_mainPool_9.jpg',
        'esparcimiento_mainPool_10.jpg',
        'esparcimiento_mainPool_11.jpg',
      ],
      slideImages: [
        'esparcimiento_mainPool_1.jpg',
        'esparcimiento_mainPool_2.jpg',
        'esparcimiento_mainPool_3.jpg',
        'esparcimiento_mainPool_4.jpg',
        'esparcimiento_mainPool_5.jpg',
        'esparcimiento_mainPool_6.jpg',
        'esparcimiento_mainPool_7.jpg',
        'esparcimiento_mainPool_8.jpg',
        'esparcimiento_mainPool_9.jpg',
        'esparcimiento_mainPool_10.jpg',
        'esparcimiento_mainPool_11.jpg',
      ],
    },
    {
      section: 'amenidades',
      path: 'gardens',
      title: 'amenities.gardens.title',
      subtitle: 'amenities.gardens.subtitle',
      name: 'amenities.gardens.name',
      desc: 'amenities.gardens.desc',
      coverImage: 'esparcimiento_jardines.jpg',
      map: 'map2',
      gallery: [
        'esparcimiento_jardines_1.jpg',
        'esparcimiento_jardines_2.jpg',
        'esparcimiento_jardines_3.jpg',
        'esparcimiento_jardines_4.jpg',
        'esparcimiento_jardines_5.jpg',
        'esparcimiento_jardines_6.jpg',
        'esparcimiento_jardines_7.jpg',
        'esparcimiento_jardines_8.jpg',
      ],
      slideImages: [
        'esparcimiento_jardines_1.jpg',
        'esparcimiento_jardines_2.jpg',
        'esparcimiento_jardines_3.jpg',
        'esparcimiento_jardines_4.jpg',
        'esparcimiento_jardines_5.jpg',
        'esparcimiento_jardines_6.jpg',
        'esparcimiento_jardines_7.jpg',
        'esparcimiento_jardines_8.jpg',
      ]
    },
    {
      section: 'amenidades',
      path: 'games',
      title: 'amenities.games.title',
      subtitle: 'amenities.games.subtitle',
      name: 'amenities.games.name',
      desc: 'amenities.games.desc',
      coverImage: 'esparcimiento_games.jpg',
      map: 'map2',
      gallery: [
        'esparcimiento_games_1.jpg',
        'esparcimiento_games_2.jpg',
        'esparcimiento_games_3.jpg',
        'esparcimiento_games_4.jpg',
        'esparcimiento_games_5.jpg',
        'esparcimiento_games_6.jpg',
        'esparcimiento_games_7.jpg',
        'esparcimiento_games_8.jpg',
        'esparcimiento_games_9.jpg',
        'esparcimiento_games_10.jpg',
        'esparcimiento_games_11.jpg',
        'esparcimiento_games_12.jpg',
        'esparcimiento_games_13.jpg',
        'esparcimiento_games_14.jpg',
      ],
      slideImages: [
        'esparcimiento_games_1.jpg',
        'esparcimiento_games_2.jpg',
      ]
    },
    {
      section: 'amenidades',
      path: 'cabanas',
      title: 'amenities.cabanas.title',
      subtitle: 'amenities.cabanas.subtitle',
      name: 'amenities.cabanas.name',
      desc: 'amenities.cabanas.desc',
      coverImage: 'esparcimiento_cabanas.jpg',
      map: 'map3',
      gallery: [
        'esparcimiento_cabanas_1.jpg',
        'esparcimiento_cabanas_2.jpg',
        'esparcimiento_cabanas_3.jpg',
      ],
      slideImages: [
        'esparcimiento_cabanas_1.jpg',
        'esparcimiento_cabanas_2.jpg',
        'esparcimiento_cabanas_3.jpg',
      ],
    },
    {
      section: 'amenidades',
      path: 'beach',
      title: 'amenities.beach.title',
      subtitle: 'amenities.beach.subtitle',
      name: 'amenities.beach.name',
      desc: 'amenities.beach.desc',
      coverImage: 'esparcimiento_beach.jpg',
      map: 'map3',
      gallery: [
        'esparcimiento_beach_1.jpg',
        'esparcimiento_beach_2.jpg',
        'esparcimiento_beach_3.jpg',
        'esparcimiento_beach_4.jpg',
        'esparcimiento_beach_8.jpg',
        'esparcimiento_beach_5.jpg',
        'esparcimiento_beach_6.jpg',
        'esparcimiento_beach_7.jpg',
        'esparcimiento_beach_9.jpg',
        'esparcimiento_beach_10.jpg',
        'esparcimiento_beach_11.jpg',
        'esparcimiento_beach_12.jpg',
      ],
      slideImages: [
        'esparcimiento_beach_9.jpg',
        'esparcimiento_beach_10.jpg',
        'esparcimiento_beach_11.jpg',
        'esparcimiento_beach_12.jpg',        
      ],
    },
    {
      section: 'amenidades',
      path: 'gym',
      title: 'amenities.gym.title',
      subtitle: 'amenities.gym.subtitle',
      name: 'amenities.gym.name',
      desc: 'amenities.gym.desc',
      coverImage: 'esparcimiento_gym.jpg',
      map: 'map3',
      gallery: [
        'esparcimiento_gym_1.jpg',
        'esparcimiento_gym_2.jpg',
        'esparcimiento_gym_3.jpg',
        'esparcimiento_gym_4.jpg',
        'esparcimiento_gym_5.jpg',
      ],
      slideImages: [
        'esparcimiento_gym_2.jpg',
        'esparcimiento_gym_3.jpg',
        'esparcimiento_gym_4.jpg',
      ]
    },
    {
      section: 'amenidades',
      path: 'pool',
      title: 'amenities.pool.title',
      subtitle: 'amenities.pool.subtitle',
      name: 'amenities.pool.name',
      desc: 'amenities.pool.desc',
      coverImage: 'esparcimiento_pool.jpg',
      map: 'map2',
      gallery: [
        'esparcimiento_pool_1.jpg',
        'esparcimiento_pool_2.jpg',
        'esparcimiento_pool_3.jpg',
        'esparcimiento_pool_4.jpg',
        'esparcimiento_pool_5.jpg',
        'esparcimiento_pool_6.jpg',
      ],
      slideImages: [
        'esparcimiento_pool_1.jpg',
        'esparcimiento_pool_2.jpg',
        'esparcimiento_pool_3.jpg',
        'esparcimiento_pool_4.jpg',
        'esparcimiento_pool_5.jpg',
        'esparcimiento_pool_6.jpg',
      ]
    },
    {
      section: 'amenidades',
      path: 'pool-bar',
      title: 'amenities.poolBar.title',
      subtitle: 'amenities.poolBar.subtitle',
      name: 'amenities.poolBar.name',
      desc: 'amenities.poolBar.desc',
      coverImage: 'esparcimiento_pool_bar.jpg',
      // map: 'map1',
      gallery: [
        'esparcimiento_pool_bar_1.jpg',
        'esparcimiento_pool_bar_2.jpg',
        'esparcimiento_pool_bar_3.jpg',
        'esparcimiento_pool_bar_4.jpg',
        'esparcimiento_pool_bar_5.jpg',
        'esparcimiento_pool_bar_6.jpg',
      ],
      slideImages: [
        'esparcimiento_pool_bar_1.jpg',
        'esparcimiento_pool_bar_2.jpg',
        'esparcimiento_pool_bar_3.jpg',
        'esparcimiento_pool_bar_4.jpg',
        'esparcimiento_pool_bar_5.jpg',
        'esparcimiento_pool_bar_6.jpg',
      ],

    },
    {
      section: 'amenidades',
      path: 'jacuzzi',
      title: 'amenities.jacuzzi.title',
      subtitle: 'amenities.jacuzzi.subtitle',
      name: 'amenities.jacuzzi.name',
      desc: 'amenities.jacuzzi.desc',
      coverImage: 'esparcimiento_outdoor_jacuzzi.jpg',
      map: 'map3',
      gallery: [
        'esparcimiento_outdoor_jacuzzi_1.jpg',
        'esparcimiento_outdoor_jacuzzi_2.jpg',
        'esparcimiento_outdoor_jacuzzi_3.jpg',
        'esparcimiento_outdoor_jacuzzi_4.jpg',
        'esparcimiento_outdoor_jacuzzi_5.jpg',
        'esparcimiento_outdoor_jacuzzi_6.jpg',
      ],
      slideImages: [
        // 'esparcimiento_outdoor_jacuzzi_1.jpg',
        'esparcimiento_outdoor_jacuzzi_3.jpg',
        'esparcimiento_outdoor_jacuzzi_4.jpg',
        'esparcimiento_outdoor_jacuzzi_5.jpg',
        // 'esparcimiento_outdoor_jacuzzi_6.jpg',
      ]
    },
    {
      section: 'amenidades',
      path: 'lounge',
      title: 'amenities.lounge.title',
      subtitle: 'amenities.lounge.subtitle',
      name: 'amenities.lounge.name',
      desc: 'amenities.lounge.desc',
      coverImage: 'esparcimiento_lounge.jpg',
      map: 'map3',
      gallery: [
        'esparcimiento_lounge_3.jpg',
        'esparcimiento_lounge_4.jpg',
        'esparcimiento_lounge_5.jpg',
        'esparcimiento_lounge_1.jpg',
        'esparcimiento_lounge_2.jpg',
      ],
      slideImages: [
        'esparcimiento_lounge_3.jpg',
        'esparcimiento_lounge_4.jpg',
        'esparcimiento_lounge_5.jpg',
        // 'esparcimiento_lounge_1.jpg',
        // 'esparcimiento_lounge_2.jpg',
      ],
    },
    // {
    //   path: 'bridge',
    //   title: 'amenities.bridge.title',
    //   subtitle: 'amenities.bridge.subtitle',
    //   coverImage: 'blank.jpg',
    // },
    // {
    //   path: 'sala',
    //   title: 'amenities.sala.title',
    //   subtitle: 'amenities.sala.subtitle',
    //   coverImage: 'blank.jpg',
    // },
    // {
    //   path: 'snack',
    //   title: 'amenities.snack.title',
    //   subtitle: 'amenities.snack.subtitle',
    //   coverImage: 'blank.jpg',
    // },
    
    
    
    // {
    //   path: 'jacuzzi2',
    //   title: 'amenities.jacuzzi2.title',
    //   subtitle: 'amenities.jacuzzi2.subtitle',
    //   coverImage: 'blank.jpg',
    // },
    // {
    //   path: 'deck-palapa',
    //   title: 'amenities.deckPalapa.title',
    //   subtitle: 'amenities.deckPalapa.subtitle',
    //   coverImage: 'blank.jpg',
    // },
    // {
    //   path: 'deck-gym',
    //   title: 'amenities.deckGym.title',
    //   subtitle: 'amenities.deckGym.subtitle',
    //   coverImage: 'blank.jpg',
    // },
    
  ];
const alojamiento = [
    {
      index: 0,
      path: 'marine-collection',
      title: 'alojamiento.marineCollection.title',
      subtitle: 'alojamiento.marineCollection.subtitle',
      name: 'alojamiento.marineCollection.name',
      coverImage: 'alojamiento_marine_collection.jpg',
      map: 'map2',
    },
    // {
    //   path: 'marine-diamond',
    //   title: 'alojamiento.marineDiamond.title',
    //   subtitle: 'alojamiento.marineDiamond.subtitle',
    //   coverImage: 'blank.jpg',
    // },
    {
      index: 1,
      path: 'karma',
      title: 'alojamiento.karma.title',
      subtitle: 'alojamiento.karma.subtitle',
      name: 'alojamiento.karma.name',
      coverImage: 'alojamiento_casa_karma.jpg',
      map: 'map3',
      desc: 'alojamiento.karma.desc',
      sections: [
        {
          title: 'alojamiento.karma.level1.title',
          size: 'alojamiento.karma.level1.size',
          image: 'alojamiento_casa_karma.jpg',
          icon: 'planta_baja',
          amenities: [
            'tv',
            'almacen',
            'lavado',
            'terraza',
            'sala_exterior',
            'alberca',
            'camastro',
          ],
        },
        {
          title: 'alojamiento.karma.level2.title',
          size: 'alojamiento.karma.level2.size',
          description: 'alojamiento.karma.level2.desc',
          icon: 'planta_alta',
          image: 'blank.jpg',
          amenities: [
            'tv',
            'almacen',
            'lavado',
            'terraza',
            'sala_exterior',
            'alberca',
            'camastro',
          ],
        },
      ],
      slideImages: [
        'alojamiento_casa_karma_1.jpg',
        'alojamiento_casa_karma_2.jpg',
        'alojamiento_casa_karma_3.jpg',
        'alojamiento_casa_karma_4.jpg',
        'alojamiento_casa_karma_5.jpg',
        'alojamiento_casa_karma_6.jpg',
        'alojamiento_casa_karma_7.jpg',
        'alojamiento_casa_karma_8.jpg',
        'alojamiento_casa_karma_9.jpg',
        'alojamiento_casa_karma_10.jpg',
        'alojamiento_casa_karma_11.jpg',
        'alojamiento_casa_karma_12.jpg',
        'alojamiento_casa_karma_13.jpg',
        'alojamiento_casa_karma_14.jpg',
        'alojamiento_casa_karma_15.jpg',
      ],
      gallery: [
        'alojamiento_casa_karma_1.jpg',
        'alojamiento_casa_karma_2.jpg',
        'alojamiento_casa_karma_3.jpg',
        'alojamiento_casa_karma_4.jpg',
        'alojamiento_casa_karma_5.jpg',
        'alojamiento_casa_karma_6.jpg',
        'alojamiento_casa_karma_7.jpg',
        'alojamiento_casa_karma_8.jpg',
        'alojamiento_casa_karma_9.jpg',
        'alojamiento_casa_karma_10.jpg',
        'alojamiento_casa_karma_11.jpg',
        'alojamiento_casa_karma_12.jpg',
        'alojamiento_casa_karma_13.jpg',
        'alojamiento_casa_karma_14.jpg',
        'alojamiento_casa_karma_15.jpg',
      ],
      floorPlans: [
        'alojamiento_casa_karma_fl_1.jpg',
        'alojamiento_casa_karma_fl_2.jpg',
        'alojamiento_casa_karma_fl_3.jpg',
      ]
    },
    {
      index: 2,
      path: 'shanti',
      title: 'alojamiento.shanti.title',
      subtitle: 'alojamiento.shanti.subtitle',
      name: 'alojamiento.shanti.name',
      desc: 'alojamiento.shanti.desc',
      coverImage: 'alojamiento_casa_shanti.jpg',
      map: 'map2',
      slideImages: [
        'alojamiento_casa_shanti_1.jpg',
        'alojamiento_casa_shanti_1.jpg',
        'alojamiento_casa_shanti_2.jpg',
        'alojamiento_casa_shanti_3.jpg',
        'alojamiento_casa_shanti_4.jpg',
        'alojamiento_casa_shanti_5.jpg',
        'alojamiento_casa_shanti_6.jpg',
        'alojamiento_casa_shanti_7.jpg',
        'alojamiento_casa_shanti_8.jpg',
        'alojamiento_casa_shanti_9.jpg',
        'alojamiento_casa_shanti_10.jpg',
        'alojamiento_casa_shanti_11.jpg',
        'alojamiento_casa_shanti_12.jpg',
        'alojamiento_casa_shanti_13.jpg',
      ],
      gallery: [
        'alojamiento_casa_shanti_1.jpg',
        'alojamiento_casa_shanti_2.jpg',
        'alojamiento_casa_shanti_3.jpg',
        'alojamiento_casa_shanti_4.jpg',
        'alojamiento_casa_shanti_5.jpg',
        'alojamiento_casa_shanti_6.jpg',
        'alojamiento_casa_shanti_7.jpg',
        'alojamiento_casa_shanti_8.jpg',
        'alojamiento_casa_shanti_9.jpg',
        'alojamiento_casa_shanti_10.jpg',
        'alojamiento_casa_shanti_11.jpg',
        'alojamiento_casa_shanti_12.jpg',
        'alojamiento_casa_shanti_13.jpg',
      ],
      floorPlans: [
        'alojamiento_casa_shanti_fl_1.jpg',       
        'alojamiento_casa_shanti_fl_2.jpg',       
        'alojamiento_casa_shanti_fl_3.jpg',       
      ],
      sections: [
        {
          title: 'alojamiento.shanti.level1.title',
          size: 'alojamiento.shanti.level1.size',
          image: 'blank.jpg',
          icon: 'piso1',
          amenities: [
            'cochera',
            'lavado',
            'tv45',
            'cocina',
            'comedor_exterior',
            'sala_exterior',
            'terraza_techada',
            'grill',
            'cocktail_pool',
            'camastro',
            'tv50',
            'frigobar',
            'acceso_alberca',
          ]
        },
        {
          title: 'alojamiento.shanti.level2.title',
          size: 'alojamiento.shanti.level2.size',
          image: 'blank.jpg',
          icon: 'piso2',
          amenities: [
            'cochera',
            'lavado',
            'tv45',
            'cocina',
            'comedor_exterior',
            'sala_exterior',
            'terraza_techada',
            'grill',
            'cocktail_pool',
            'camastro',
            'tv50',
            'frigobar',
            'acceso_alberca',
          ]
        },
        {
          title: 'alojamiento.shanti.level3.title',
          size: 'alojamiento.shanti.level3.size',
          image: 'blank.jpg',
          icon: 'piso3',
          amenities: [
            'cochera',
            'lavado',
            'tv45',
            'cocina',
            'comedor_exterior',
            'sala_exterior',
            'terraza_techada',
            'grill',
            'cocktail_pool',
            'camastro',
            'tv50',
            'frigobar',
            'acceso_alberca',
          ]
        },
      ]
    },
    {
      index: 3,
      path: 'malabar',
      coverImage: 'alojamiento_casa_malabar.jpg',
      title: 'alojamiento.malabar.title',
      name: 'alojamiento.malabar.name',
      subtitle: 'alojamiento.malabar.subtitle',
      map: 'map3',
      sections: [
        {
          title: 'alojamiento.malabar.floor1.title',
          size: 'alojamiento.malabar.floor1.size',
          description: 'alojamiento.malabar.floor1.desc',
          image: 'blank.jpg',
        },
        {
          title: 'alojamiento.malabar.floor2.title',
          size: 'alojamiento.malabar.floor2.size',
          description: 'alojamiento.malabar.floor2.desc',
          image: 'blank.jpg',
        },
      ],
      slideImages: [
        'alojamientos_malabar_floor2_2.jpg',
        'alojamientos_malabar_floor2_4.jpg',
        'alojamientos_malabar_floor2_7.jpg',
        'alojamientos_malabar_floor2_9.jpg',
        'alojamientos_malabar_floor2_10.jpg',
        'alojamientos_malabar_floor1_1.jpg',
        'alojamientos_malabar_floor1_3.jpg',
        'alojamientos_malabar_floor1_5.jpg',
        'alojamientos_malabar_floor1_6.jpg',
      ],
      gallery: [
        'alojamientos_malabar_floor1_7.jpg',
        'alojamientos_malabar_floor2_1.jpg',
        'alojamientos_malabar_floor2_2.jpg',
        'alojamientos_malabar_floor2_3.jpg',
        'alojamientos_malabar_floor2_4.jpg',
        'alojamientos_malabar_floor2_6.jpg',
        'alojamientos_malabar_floor2_7.jpg',
        'alojamientos_malabar_floor2_8.jpg',
        'alojamientos_malabar_floor2_9.jpg',
        'alojamientos_malabar_floor2_11.jpg',
        'alojamientos_malabar_floor2_10.jpg',
        'alojamientos_malabar_floor2_5.jpg',
        'alojamientos_malabar_floor1_1.jpg',
        'alojamientos_malabar_floor1_2.jpg',
        'alojamientos_malabar_floor1_3.jpg',
        'alojamientos_malabar_floor1_4.jpg',
        'alojamientos_malabar_floor1_5.jpg',
        'alojamientos_malabar_floor1_6.jpg',
      ]
    },
    {
      index: 3,
      path: 'diamond',
      coverImage: 'alojamiento_marine_diamond.jpg',
      title: 'alojamiento.marineDiamond.title',
      name: 'alojamiento.marineDiamond.name',
      subtitle: 'alojamiento.marineDiamond.subtitle',
      map: 'map2',
      desc: 'alojamiento.marineDiamond.desc',
      sections: [
        // {
        //   title: 'alojamiento.malabar.floor1.title',
        //   size: 'alojamiento.malabar.floor1.size',
        //   description: 'alojamiento.malabar.floor1.desc',
        //   image: 'blank.jpg',
        // },
      ],
      slideImages: [
        'alojamiento_marine_diamond_1.jpg',
        'alojamiento_marine_diamond_2.jpg',
        'alojamiento_marine_diamond_3.jpg',
        'alojamiento_marine_diamond_4.jpg',
        'alojamiento_marine_diamond_5.jpg',
        // 'alojamiento_marine_diamond_6.jpg',
        // 'alojamiento_marine_diamond_7.jpg',
        // 'alojamiento_marine_diamond_8.jpg',
        // 'alojamiento_marine_diamond_9.jpg',
        // 'alojamiento_marine_diamond_10.jpg',
        // 'alojamiento_marine_diamond_11.jpg',
        // 'alojamiento_marine_diamond_12.jpg',
      ],
      gallery: [
        'alojamiento_marine_diamond_1.jpg',
        'alojamiento_marine_diamond_2.jpg',
        'alojamiento_marine_diamond_3.jpg',
        'alojamiento_marine_diamond_4.jpg',
        'alojamiento_marine_diamond_5.jpg',
        'alojamiento_marine_diamond_6.jpg',
        'alojamiento_marine_diamond_7.jpg',
        'alojamiento_marine_diamond_8.jpg',
        'alojamiento_marine_diamond_9.jpg',
        'alojamiento_marine_diamond_10.jpg',
        'alojamiento_marine_diamond_1.gif',
        'alojamiento_marine_diamond_2.gif',
        'alojamiento_marine_diamond_11.jpg',
        'alojamiento_marine_diamond_3.gif',
        'alojamiento_marine_diamond_4.gif',
        'alojamiento_marine_diamond_5.gif',
        'alojamiento_marine_diamond_6.gif',
        'alojamiento_marine_diamond_12.jpg',
      ],
      floorPlans: [
        'alojamiento_marine_diamond_fl_1.jpg',
        'alojamiento_marine_diamond_fl_2.jpg',
        'alojamiento_marine_diamond_fl_3.jpg',
      ]
    },
  ];

  export default {
    consumo,
    destinos,
    amenities,
    alojamiento,
  };