import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { EffectCoverflow, Pagination } from 'swiper/modules';
import { useNavigate, useLocation } from "react-router-dom";


import { useTranslation, Trans } from 'react-i18next';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import { type Swiper as SwiperRef } from 'swiper'

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

export interface Slide {
    path: String,
    title: string,
    subtitle: string,
    coverImage?: string,
    link?: string,
}
interface Props {
    section: String,
    slides: Slide[],
    index?: number,
}

const View = ({ slides, section, index = 0 }:Props) => {
    // console.log('SWIPER INDEX', index)
    const navigate = useNavigate();
    // let _swiper = useSwiper();
    const _swiper = useRef<SwiperRef>();

    const location = useLocation();
    // const { index } = location.state || { from : '' };
    const { t } = useTranslation();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [initIndex, setInitIdex] = useState(index || 0);
    const [isSwiperReady, setIsSwiperReady] = useState(false);

    // useEffect(() => {
    //     if (_swiper && index) {
    //         // console.log('effect', index);
    //         _swiper.slideTo(index);
    //     }
    // }, [index, isSwiperReady])

    // console.log('from index', index); // output: "the-page-id"

    // if (from !== '') {
    //     slides.find((v:any, k:number) => {
    //         if (v.path === from) {
    //             if (initIndex !== k) {
    //                 // setInitIdex(0);
    //             }
    //             return v;
    //         }
    //     });
    // }

    // const images = [{
    //         title: 'asdf',
    //         subtitle: 'asdf',
    //         image: 'a.jpg',
    //     },
    //     {
    //         title: 'asdf',
    //         subtitle: 'asdf',
    //         image: 'b.jpg',
    //     },
    //     {
    //         title: 'asdf',
    //         subtitle: 'asdf',
    //         image: 'c.jpg',
    //     }
    // ];
    const goToDetail = (slide:any) => {
        console.log('GODETAIL', slide);
        // navigate(`${slides[slide.realIndex].path}`);
        navigate(`${slides[slide].path}`, { state: { index: currentIndex }});
    };
    const test = () => {
        // console.log('slide', _swiper);
        if (_swiper) {
            // _swiper.current.slideTo(index)
        }
    }
    const goToDetailAlt = (swiper:any) => {
      // console.log('goToDetailAlt', swiper.realIndex, currentIndex);
      if (!swiper) return;
      if (swiper.realIndex === undefined) {
        // console.log('swiper.realIndex', swiper.realIndex);
        return;
      }
      if (!slides[swiper.realIndex]) {
        // console.log('slides[swiper.realIndex]', slides[swiper.realIndex])
        return;
      }
      navigate(`${slides[swiper.realIndex].path}`, { state: { index: currentIndex }});
      
    }
    return (
        <>
        {/* <button onClick={test}>sssss</button> */}
        
        <Swiper
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            loop={slides.length > 2 ? true : false}
            initialSlide={index}
            slidesPerView={3}
            coverflowEffect={{
            rotate: 0,
            stretch: -100,
            depth: 250,
            modifier: 1,
            slideShadows: false,
            }}
            pagination={false}
            modules={[EffectCoverflow]}
            className="coverflow"
            slideActiveClass="active"
            onClick={goToDetailAlt}
            onSlideChange={(x:any) => {
                setCurrentIndex(x.realIndex)
                // console.log('current', x.realIndex)
            }}
            // onUpdate={() => _swiper.current?.slideTo(index)}
            onInit={() => console.log('INIT swiper')}
            onSwiper={(swiper:any) => {
              console.log('onSwiper')
                _swiper.current = swiper
                setIsSwiperReady(true)
                
                // _swiper.current?.slideTo(index)
                // console.log('SWIPRE READYYYY', _swiper)
            }}
            // onSliderFirstMove={(x:any) => {
            //     console.log('startmove', x.realIndex);
            //     // setCurrentIndex(x.realIndex)
            // }}
            // onslid
        >
            {slides.map((v:Slide, k:number) => (
                <SwiperSlide key={k} onClick={() => {}}>
                    <img src={`/assets/${v.coverImage}`} />
                    <div className='swiper-slider-content textAlignCenter' >
                        {/* <Link to={v.link || '/detail'}> */}
                            {currentIndex === k && <div className='animation-bottomToTop'>
                            <h2 className='fontZapfino textWhite font6'>{t(v.title)}</h2>
                            {/* <h2 className='fontZapfino textWhite font6'>{currentIndex}</h2> */}
                            <h1 className='fontCoconat textWhite font6'>{t(v.subtitle).toLocaleUpperCase()}</h1>
                            </div>}
                        {/* </Link> */}
                    </div>
                        
                    {/* <button>asdf</button> */}
                </SwiperSlide>
            ))}
        </Swiper>
        </>
    );
};

export default View;
